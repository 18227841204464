export const Constant = {
  ADVERTISER: 'ADVERTISER',
  PUBLISHER: 'PUBLISHER',

  COMPANY_NAME: 'companyName',
  COMPANY_IDENTIFICATION_NUMBER: 'companyId',
  COMPANY_COUNTRY: 'companyCountry',
  COMPANY_ADDRESS: 'companyAddress',

  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  COUNTRY: 'countryOfResidency',
  ADDRESS: 'address',

  CRYPTO_WALLET_ADDRESS: 'CryptoWalletAddress',
  BANK_BENEFICIARY_NAME: 'BankBeneficiaryName',
  BANK_ACCOUNT_NUMBER: 'BankAccountNumber',
  BANK_SWIFT_CODE: 'BankSwiftCode',
  BANK_NAME: 'BankName',

  CRYPTO_WITHDRAWL_OPTION: 'CryptoWithdrawlOption',
  BANK_WITHDRAWL_OPTION: 'BankWithdrawlOption',

  IDENTIFICATION_DETAILS: 'IdentificationDetails',
  UPLOAD_DOCUMENTS: 'UploadDocuments',
  WITHDRAWL_OPTION: 'WithdrwalOption',

  CONTENT_OFFERING: 'contentOffering',
  ADS_OFFERING: 'adsOffering',
  BOTH: 'both',
  CUSTOM_PACKAGE: 'customPackage',

  PAY_VIA_STRIPE: 'payViaStripe',
  PAY_VIA_COINGATE: 'payViaCoingate',

  PAGE_SIZE: 9,
  INCREMENT_PAGENUMBER: 'incrementPageNumber',
  DECREMENT_PAGENUMBER: 'decrementPageNumber',

  FILE_SIZE: 5000000, // 5MB

  DOCUMENT_LIST: ['Government Issued ID', 'Passport', 'Driving License'],
}
