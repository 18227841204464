import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.black,
    fontWeight: 'bold',
    border: '1px solid black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    // border:"1px solid black",
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
    // border:"1px solid black",
  },
  // border:"1px solid black",
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}))

export default function WalletPublisherTableVertical({ data }) {
  // console.log(data)
  return (
   
      <Table sx={{ width: "100%"}} className="vT" aria-label="customized table">
        {data?.map((row) => {
          return (
            <>
              <TableRow>
                <StyledTableCell className='verticalTableId' variant="head" align="center">
                  Date
                </StyledTableCell>
                <StyledTableCell className='verticalTableValue'
                  sx={{
                    border: '1px solid black',
                  }}
                  align="center"
                >
                  {new Date(row?.date).toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className='verticalTableId' variant="head" align="center">
                  ID
                </StyledTableCell>
                <StyledTableCell className='verticalTableValue'
                  sx={{
                    border: '1px solid black',
                  }}
                  align="center"
                >
                  {row?.id}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className='verticalTableId' variant="head" align="center">
                  Status
                </StyledTableCell>
                <StyledTableCell className='verticalTableValue'
                  sx={{
                    border: '1px solid black',
                  }}
                  align="center"
                >
                  {row?.status}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className='verticalTableId' variant="head" align="center">
                  Description
                </StyledTableCell>
                <StyledTableCell className='verticalTableValue'
                  sx={{
                    border: '1px solid black',
                  }}
                  align="center"
                >
                  {row?.description}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className='verticalTableId' variant="head" align="center">
                  Amount
                </StyledTableCell>
                <StyledTableCell className='verticalTableValue'
                  sx={{
                    border: '1px solid black',
                  }}
                  align="center"
                >
                  {row?.amount}
                </StyledTableCell>
              </TableRow>
              <br />
            </>
          )
        })}
      </Table>

  )
}
