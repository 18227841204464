export const actionType = {
    SHOW_DETAILS_OF_INDIVIDUAL_MARKETPLACE:
      "SHOW_DETAILS_OF_INDIVIDUAL_MARKETPLACE",
    //types for cart
    
    ADD_TO_CART: "ADD_TO_CART",
    ACTIVATE_CART: "ACTIVATE_CART",
    REMOVE_FROM_CART: "REMOVE_FROM_CART",
    ADD_QUANTITY: "ADD_QUANTITY",
    SUB_QUANTITY: "SUB_QUANTITY",
    EMPTY_CART: "EMPTY_CART",
    AUTHENTICATED_USER_DETAILS: "AUTHENTICATED_USER_DETAILS",
    ORDER_DETAILS: "ORDER_DETAILS",
    SET_TOKEN_TO_REDUX: "SET_TOKEN_TO_REDUX",
    SET_USER_DATA_TO_REDUX: "SET_USER_DATA_TO_REDUX",
  };
  