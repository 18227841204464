import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function WalletPublisherTable({ data }) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 600 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ border: '2px solid black', fontWeight: 'bold' }}
              align="center"
            >
              Date
            </TableCell>
            <TableCell
              sx={{ border: '2px solid black', fontWeight: 'bold' }}
              align="center"
            >
              ID
            </TableCell>
            <TableCell
              sx={{ border: '2px solid black', fontWeight: 'bold' }}
              align="center"
            >
              Status
            </TableCell>
            <TableCell
              sx={{ border: '2px solid black', fontWeight: 'bold' }}
              align="center"
            >
              Description
            </TableCell>
            <TableCell
              sx={{ border: '2px solid black', fontWeight: 'bold' }}
              align="center"
            >
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row?.id}>
              <TableCell
                sx={{ border: '2px solid black', fontWeight: 'bold' }}
                align="center"
              >
                {new Date(row?.date)
                  .toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })
                  .replace(',', '')}
              </TableCell>
              <TableCell sx={{ border: '2px solid black' }} align="center">
                {row?.id}
              </TableCell>

              <TableCell sx={{ border: '2px solid black' }} align="center">
                {row?.status}
              </TableCell>
              <TableCell sx={{ border: '2px solid black' }} align="center">
                {row?.description}
              </TableCell>
              <TableCell sx={{ border: '2px solid black' }} align="center">
                {row?.amount}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
