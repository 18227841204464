import React, { useContext, useEffect, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AuthUserContext from '../../authentication/AuthUserContext'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { addToCart } from '../../redux/actions'
import { addToCartAPI } from '../../service/api'

function PackageCardNew({ popular, item }) {
  const { _id, description, offerPrice = 0, addons = [] } = item // destructure the item and provide defaults if needed
  const descriptions = description?.split(';')
  const [selectedAddons, setSelectedAddons] = useState([])
  const [totalPrice, setTotalPrice] = useState(offerPrice)

  const [isItemInCart, setIsItemInCart] = useState(false)
  const authUser = useContext(AuthUserContext)
  const cartData = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null
  const cartDataIds = cartData ? cartData.map((el) => el?.packageId) : []
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setIsItemInCart(cartDataIds.includes(_id))
  }, [authUser, _id, cartDataIds])

  useEffect(() => {
    console.log('total price', totalPrice)
  }, [totalPrice])

  const handleAddonAdd = (addon, basePrice) => {
    setSelectedAddons([...selectedAddons, addon])
    setTotalPrice((prevPrice) => prevPrice + addon.price)
  }

  const handleAddonRemoved = (addon, basePrice) => {
    setSelectedAddons(
      selectedAddons.filter((selectedAddon) => selectedAddon !== addon)
    )
    setTotalPrice((prevPrice) => prevPrice - addon.price)
  }

  const getPayload = (addonNames, priceToSend) => ({
    packageId: _id,
    type: 'package',
    name: `${item?.name} ${
      addonNames.length > 0 ? '( ' + addonNames + ' )' : ''
    }`,
    userId: authUser._id,
    image: item.icon,
    // price: totalPrice,
    price: priceToSend,
  })

  const handleAddToCart = async () => {
    if (!authUser) {
      navigate('/sign-in', { state: { from: location } })
      return
    }

    const priceToSend = selectedAddons.length > 0 ? totalPrice : item.offerPrice

    if (cartDataIds.includes(_id)) {
      alert('Already added to the cart')
      return
    }

    const addonNames = selectedAddons?.map((addon) => addon.title).join(', ')
    const payload = getPayload(addonNames, priceToSend)
    console.log(payload)

    const data = await addToCartAPI(payload)
    if (data.success) {
      // check API response before proceeding
      console.log('item added........', data)
      dispatch(addToCart(payload))
      setIsItemInCart(true)
      navigate('/cart-checkout')
    } else {
      console.error('Failed to add item to cart:', data.error)
    }
  }

  // const handleRemoveFromCart = async () => {
  //   const addonNames = selectedAddons?.map((addon) => addon.title).join(', ')
  //   const payload = getPayload(addonNames)

  //   const data = await deleteFromCartAPI({
  //     userId: authUser._id,
  //     type: 'package',
  //     packageId: _id,
  //   })
  //   if (data.success) {
  //     // check API response before proceeding
  //     console.log('removed...', data)
  //     dispatch(removeFromCart(payload))
  //     setIsItemInCart(false)
  //   } else {
  //     console.error('Failed to remove item from cart:', data.error)
  //   }
  // }

  const handleScrollToCart = (price) => {
    const cartContainer = document.getElementById('add-to-cart-container')
    if (cartContainer) {
      cartContainer.scrollIntoView({ behavior: 'smooth' })
    }
    setTotalPrice(price)
  }

  return (
    <div className="">
      <div
        className={
          item?.type === 'coloured'
            ? `bg-[#4353FF] text-white mt-7 sm:mt-0  ${
                item.hotSelling
                  ? "before:absolute relative before:content-['Popular'] before:bg-black before:top-[-32px]   md:before:top-[-36px]   lg:before:top-[-40px] before:rounded-t-[4px] before:text-sm  md:before:text-base  lg:before:text-lg before:uppercase  before:px-4 before:py-1.5  before:text-white rounded-sm"
                  : ''
              } `
            : `bg-[#ECEEFF]  ${
                item.hotSelling
                  ? "before:absolute relative before:content-['Popular'] before:bg-black before:top-[-32px]   md:before:top-[-36px]   lg:before:top-[-40px] before:rounded-t-[4px] before:text-sm  md:before:text-base  lg:before:text-lg before:uppercase  before:px-4 before:py-1.5  before:text-white rounded-sm"
                  : ''
              }`
        }
      >
        <div className="flex flex-col justify-center items-center p-6 border-b-[1.5px] border-b-white">
          <h1
            className={
              popular
                ? 'mt-8 text-2xl font-semibold text-white  mb-6 font-inter'
                : 'mt-8 text-2xl font-semibold text-[#4353FF]  mb-6 font-inter'
            }
          >
            {item.name}
          </h1>
          <div className="flex justify-center items-center gap-6 mb-4">
            <p className="line-through font-inter">${item?.endingPrice}</p>
            <h1 className="base-price text-3xl font-bold font-inter">
              ${item?.offerPrice}
            </h1>
          </div>
          <button
            onClick={() => handleScrollToCart(item.offerPrice)}
            className={
              popular
                ? 'w-2/3 xl:w-1/2 decoration-none text-center border  hover:bg-black hover:text-white hover:border-white  bg-white rounded-sm text-black font-semibold px-4 py-2 text-lg  mt-2.5 mb-16 font-inter add1'
                : 'w-2/3 xl:w-1/2 decoration-none text-center bg-black rounded-sm border hover:bg-white hover:text-black hover:border-black  text-white px-4 py-2 text-lg  mt-2.5 mb-16 font-inter add1'
            }
          >
            Add to Cart
          </button>
        </div>
        <div className={`flex flex-col p-5  md:p-6 h-[490px]`}>
          <p className="text-center text-lg font-semibold mb-6 font-inter">
            What’s Included
          </p>
          <ul className={`text-[16px] leading-[3rem] mb-12 font-inter`}>
            {descriptions?.map((item, i) => {
              return <li key={i}>{item}</li>
            })}
          </ul>
        </div>

        <div className="flex flex-col p-5  md:p-6  border-b-[1.5px] border-b-white">
          <p className="text-center text-lg font-semibold mb-8 font-inter">
            Guaranteed Placement On
          </p>
          <img src={item?.image} className="h-[366px] w-full mb-16" alt="" />
        </div>
        <div className="flex flex-col p-5 md:p-6  mb-12">
          <p className="text-center text-lg font-semibold mb-8 font-inter">
            Add-Ons to Boost Campaign
          </p>
          <div className="flex flex-col w-full gap-6">
            {item?.addons?.map((addon, i) => (
              <div className="flex justify-between" key={i}>
                <div
                  className={`flex justify-between bg-white items-center w-[65%] rounded border border-black p-2`}
                >
                  <div className="h-[50px] w-full">
                    <img src={addon.logo} className="w-full h-full" alt="" />
                  </div>

                  {/* <div className="h-[50px] w-full">
                    <embed
                      src={addon.logo}
                      type="image/svg+xml"
                      className="w-full h-full"
                    />
                  </div> */}
                </div>
                <div className="flex justify-center items-center gap-1  lg::gap-3">
                  <p className="addon-price font-semibold text-lg">
                    ${addon.price}
                  </p>

                  {selectedAddons.includes(addon) ? (
                    <AddCircleIcon
                      onClick={() => handleAddonRemoved(addon, item.offerPrice)}
                    />
                  ) : (
                    <AddCircleOutlineIcon
                      onClick={() => handleAddonAdd(addon, item.offerPrice)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="flex bg-black text-white p-5  md:p-6 justify-between items-center"
          id="add-to-cart-container"
        >
          <div className="block">
            {selectedAddons.length > 0 ? (
              <h1 className="total-price text-3xl font-semibold">
                ${totalPrice}
              </h1>
            ) : (
              <h1 className="offer-price text-3xl font-semibold">
                ${item.offerPrice}
              </h1>
            )}
            {selectedAddons.length > 0 && (
              <p className="text-sm">with add on</p>
            )}
          </div>
          {isItemInCart ? (
            <button className="w-1/2 h-fit  decoration-none text-center border hover:bg-black hover:text-white hover:border-white  bg-white rounded-sm text-black font-semibold py-2  text-lg flex justify-center items-center font-inter">
              Added To Cart
            </button>
          ) : (
            <button
              onClick={() => handleAddToCart()}
              className="w-1/2 h-fit  decoration-none text-center border  hover:bg-black hover:text-white hover:border-white  bg-white rounded-sm text-black font-semibold py-2  text-lg flex justify-center items-center font-inter"
            >
              Add to Cart
            </button>
          )}
        </div>
      </div>
      <div className="text-center mt-8 text-md text-[#646464]">
        <a
          href="http://koinpr.com/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Not sure? Enquire now 📞
        </a>
      </div>
    </div>
  )
}

export default PackageCardNew
