import AuthUserContext from '../../../authentication/AuthUserContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Constant } from '../../../common'
import { createStripeCheckoutSession, createCrypto } from '../../../service/api'
import { useDispatch } from 'react-redux'
import { saveOrderDetails, clearCart } from '../../../service/api'
import { emptyCart } from '../../../redux/actions'

const PayButton = ({ cartItem, paymentMethod }) => {
  const navigate = useNavigate()
  const authUser = useContext(AuthUserContext)
  const dispatch = useDispatch()
  console.log(paymentMethod)
  const handleCheckoutStripe = async () => {
    const data = {
      cartItem,
      userId: authUser._id,
    }
    await createStripeCheckoutSession(data)
  }

  const handleGetOrderName = (name) => {
    let idx = -1
    let spaces = 0

    for (let i = 0; i < name.length; i++) {
      if (name[i] == ' ') {
        spaces = spaces + 1
      }

      if (spaces == 3) {
        idx = i + 1
        break
      }
    }

    if (idx != -1) {
      return name.substring(idx)
    }

    return name
  }

  const getCategory = (name) => {
    let category = ''

    for (let i = 0; i < name.length; i++) {
      if (name[i] == ' ') {
        break
      }
      category += name[i]
    }

    if (category.toLowerCase() == 'press') {
      return 'Press Release'
    }

    if (category.toLowerCase() == 'sponsored') {
      return 'Sponsored Article'
    }

    if (category.toLowerCase() == 'button') {
      return 'Button Ads'
    }

    if (category.toLowerCase() == 'banner') {
      return 'Banner Ads'
    }
  }

  const getProductsName = () => {
    let description = 'Placed Order for '

    // const categoryWiseOrder = {}

    for (let i = 0; i < cartItem.length - 1; i++) {
      description = description + cartItem[i].name + ', '
    }
    description += cartItem[cartItem.length - 1].name

    // Object.keys(categoryWiseOrder).forEach((key, idx) => {
    //   description += key + ' ('
    //   for (let i = 0; i < categoryWiseOrder[key].length; i++) {
    //     description += categoryWiseOrder[key][i]
    //     if (i != categoryWiseOrder[key].length - 1) {
    //       description += ', '
    //     }
    //   }
    //   description += ') '
    // })

    return description
  }

  const handleSuccess = async () => {
    let finalprice = 0
    for (const { price } of cartItem) {
      finalprice += price
    }
    const data = {
      desc: getProductsName(),
      amount: finalprice,
      userId: authUser?._id,
      status: 'pending',
    }

    await saveOrderDetails(data)
    clearCart(authUser?._id)
    dispatch(emptyCart())
    window.location.href = 'https://www.koinpr.com/cryptocheckout'
  }

  const handleCheckoutCrypto = async () => {
    const data = {
      cartItem,
      userId: authUser._id,
    }
    await createCrypto(data)
    await handleSuccess()
  }

  const handleCheckout = () => {
    if (paymentMethod == Constant.PAY_VIA_STRIPE) {
      handleCheckoutStripe()
    } else {
      // handleCheckoutCoingate
      handleCheckoutCrypto()
    }
  }
  return (
    <>
      <button onClick={handleCheckout}>
        Place Order
        <ArrowForwardIcon sx={{ fontSize: '18px', marginLeft: '5px' }} />
      </button>
    </>
  )
}

export default PayButton
