import axios from 'axios'
import Cookies from 'universal-cookie'

const baseUrl = 'https://user.koinpr.com/api'
// const baseUrl = 'http://localhost:5000/api'
// const baseUrl = 'http://ec2-16-16-202-64.eu-north-1.compute.amazonaws.com/api'

const cookies = new Cookies()

const config = {
  headers: {
    Authorization: 'Bearer ' + cookies.get('auth-token'),
    Apikey: 'koinpratodayqproductrsstoken',
  },
}

export function saveDocuments(data) {
  const newConfig = {
    headers: {
      Authorization: 'Bearer ' + cookies.get('auth-token'),
      Apikey: 'koinpratodayqproductrsstoken',
      accept: 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  }

  return axios
    .post(`${baseUrl}/upload/`, data, newConfig)
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      return []
    })
}

export function getPublisher() {
  return axios
    .get(`${baseUrl}/publisher/get-all-verified`, config)
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      return []
    })
}

export function getPackage() {
  return axios
    .get(`${baseUrl}/package/get-all/`, config)
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      return []
    })
}

export function getPackageRange() {
  return axios
    .get(`${baseUrl}/package/get-range/`, config)
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      return []
    })
}

export const getRegionsDataApi = async () => {
  return axios
    .get(baseUrl + `/publisher/get-all-regions`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export const getBannerDataApi = async () => {
  return axios
    .get(baseUrl + `/banner/get-all`, config)
    .then((res) => {
      return res
    })
    .catch((err) => err)
}

export function getListing() {
  return axios
    .get(`${baseUrl}/publisher/get-all/?verifiedByAdmin=true`, config)
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      return []
    })
}

export function getListingById(id) {
  return axios
    .get(`${baseUrl}/listing/get-one/${id}`, config)
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      // console.log(err.message)
      return []
    })
}

export function getPublisherById(id) {
  return axios
    .get(`${baseUrl}/publisher/get-one/${id}`, config)
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      // console.log(err.message)
      return []
    })
}

export function saveUserDetails(id, data) {
  console.log(id, data)
  return axios
    .patch(`${baseUrl}/user/updateUser/${id}`, data, config)
    .then((res) => {
      return res.data.user
    })
    .catch((err) => {
      console.log(err.message)
      return null
    })
}

export function getUserByToken() {
  // cookies.remove('cart')
  const token = cookies.get('auth-token')
  // console.log('token', token)
  return axios
    .post(`${baseUrl}/user/get-user-by-token`, {}, config)
    .then(async (res) => {
      console.log('userId........................', res.data.user._id)
      return await getCartData(res.data.user._id).then((resp) => res.data.user)
      // return res.data.user;
    })
    .catch((err) => {
      // console.log(err.message)
      return null
    })
}

export const getCartData = async (userId) => {
  return axios
    .get(baseUrl + `/cart/get-items/${userId}`, config)
    .then((res) => {
      console.log('cart.............', JSON.stringify(res.data.data))
      localStorage.setItem('cart', JSON.stringify(res.data.data))
      return res.data
    })
    .catch((err) => {
      // console.log(err)
    })
}

export const addListingAPI = async (formdata) => {
  return axios
    .post(baseUrl + '/listing/add', formdata, config)
    .then((res) => {
      if (res?.data?.success) {
        const data = {
          notificationType: 'success',
          notificationMessage: res?.data?.message,
        }
        return data
      }
      if (!res?.data?.success) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      if (err?.response?.data?.success == false) {
        const data = {
          notificationType: 'error',
          notificationMessage: err?.response?.data?.message,
        }
        return data
      }
      return {
        notificationType: 'error',
        notificationMessage: 'some error occurred',
      }
    })
}

export const getUserAPI = async () => {
  const auth = cookies.get('auth-token')

  if (!auth) {
    return { isUserAuthenticated: false }
  }
  return axios
    .post(baseUrl + '/user/get-user-by-token', {}, config)
    .then((res) => {
      // cookies.set("auth-token",res.data.jwtToken);
      return res.data
    })
    .catch((err) => {
      throw err
    })
}

export const signUpAPI = async (data) => {
  return axios
    .post(baseUrl + '/user/sign-up', data)
    .then((res) => {
      // console.log('response', res?.data)
      if (res?.data?.success) {
        cookies.set('auth-token', res.data.data.jwtToken, {
          path: '/',
        })
        const data = {
          notificationType: 'success',
          notificationMessage: 'You Are Registered Successfully',
        }
        return data
      }
      if (!res?.data?.success) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      if (err?.response?.data?.success == false) {
        const data = {
          notificationType: 'error',
          notificationMessage: err?.response?.data?.message,
        }
        return data
      }
      return {
        notificationType: 'error',
        notificationMessage: 'some error occurred',
      }
    })
}

export const signInAPI = async (data) => {
  return axios
    .post(baseUrl + '/user/login', data)
    .then((res) => {
      // console.log('response', res?.data)
      if (res?.data?.success) {
        cookies.set('auth-token', res?.data?.user?.token, { path: '/' })
        const data = {
          notificationType: 'success',
          notificationMessage: 'Successfully logged in',
        }
        return data
      }
      if (!res?.data?.success) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      if (err?.response?.data?.success == false) {
        const data = {
          notificationType: 'error',
          notificationMessage: err?.response?.data?.message,
        }
        return data
      }
      return {
        notificationType: 'error',
        notificationMessage: 'some error occurred',
      }
    })
}

export const addTelegramAPI = async (data, token, userid) => {
  return axios
    .patch(
      baseUrl + '/user/updateUser/' + userid,
      {
        telegram: data,
      },
      config
    )
    .then((res) => {
      console.log(res)
      if (res?.data?.success) {
        const data = {
          notificationType: 'success',
          notificationMessage: res?.data?.message,
        }
        return data
      }
      if (!res?.data?.success) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      return {
        notificationType: 'error',
        notificationMessage: 'Some error occurred',
      }
    })
}

export const resetPasswordAPI = async (data) => {
  const auth = ''

  return axios
    .post(
      baseUrl + '/password-reset',
      {
        email: data,
      },
      config
    )
    .then((res) => {
      // console.log(res.data)
      if (res?.data?.success) {
        const data = {
          notificationType: 'success',
          notificationMessage: res?.data?.message,
        }
        return data
      }
      if (!res?.data?.success) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      if (err?.response?.data?.success == false) {
        const data = {
          notificationType: 'error',
          notificationMessage: err?.response?.data?.message,
        }
        return data
      }
      return {
        notificationType: 'error',
        notificationMessage: 'some error occurred',
      }
    })
}

export const verifyOtpAPI = async (data) => {
  // const userid="63fb0138180c4b13a719c36b";
  const userdata = await getUserAPI()
  const userid = userdata.user._id
  // console.log('otp', data)
  // console.log('userid', userid)
  return axios
    .post(
      baseUrl + '/user/verifyOtp',
      {
        userId: userid,
        otp: data,
      },
      config
    )
    .then((res) => {
      if (res?.data?.status === 'Verified') {
        // console.log(res?.data)
        const data = {
          notificationType: 'success',
          notificationMessage: res?.data?.message,
        }
        return data
      }
      if (res?.data?.status !== 'Verified') {
        // console.log(res?.data)
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      return {
        notificationType: 'error',
        notificationMessage: 'some error occurred',
      }
    })
}

export const verifyUrlAPI = async (data) => {
  const url = baseUrl + `/password-reset/${data.id}/${data.token}`
  return axios
    .get(url)
    .then((resp) => {
      return resp.data
    })
    .catch((err) => err.response.data)
}

export const setPasswordAPI = async (data, password) => {
  const url = baseUrl + `/password-reset/${data.id}/${data.token}`
  return axios
    .post(url, { password }, config)
    .then((res) => {
      if (res?.data?.success) {
        const data = {
          notificationType: 'success',
          notificationMessage: res?.data?.message,
        }
        return data
      }
      if (!res?.data?.success) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      if (err?.response?.data?.success == false) {
        const data = {
          notificationType: 'error',
          notificationMessage: err?.response?.data?.message,
        }
        return data
      }
      return {
        notificationType: 'error',
        notificationMessage: 'some error occurred',
      }
    })
}

export const resendOtpAPI = async (email, userid) => {
  return axios
    .post(
      baseUrl + '/user/resendOtpVerification',
      {
        userId: userid,
        email: email,
      },
      config
    )
    .then((res) => {
      // console.log(res.data)
      if (res?.data?.status) {
        const data = {
          notificationType: 'success',
          notificationMessage: res?.data?.message,
        }
        return data
      }
      if (!res?.data?.status) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      if (err?.response?.data?.status == false) {
        const data = {
          notificationType: 'error',
          notificationMessage: err?.response?.data?.message,
        }
        return data
      }

      return {
        notificationType: 'error',
        notificationMessage: 'Some error occurred',
      }
    })
}

export const changeEmailAPI = async (
  email,
  token,
  userid,
  setEmail,
  oldemail
) => {
  return axios
    .patch(
      baseUrl + `/user/updateEmail/${userid}`,
      {
        email: email,
      },
      config
    )
    .then((res) => {
      if (res?.data?.success) {
        // console.log(res?.data)
        cookies.set('auth-token', res.data.newToken, {
          path: '/',
        })
        // await resendOtpAPI(email,userid);
        const data = {
          notificationType: 'success',
          notificationMessage: res?.data?.message,
        }
        return data
      }
      if (!res?.data?.status) {
        const data = {
          notificationType: 'error',
          notificationMessage: res?.data?.message,
        }
        return data
      }
    })
    .catch((err) => {
      setEmail(oldemail)
      if (err?.response?.data?.success == false) {
        const data = {
          notificationType: 'error',
          notificationMessage: err?.response?.data?.message,
        }
        return data
      }

      return {
        notificationType: 'error',
        notificationMessage: 'Some error occurred',
      }
    })
}

export async function getAllOrderHistory(userId) {
  const getOrderData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/order?userId=${userId}`,
        config
      )
      const orderData = response.data.OrderList.map((order) => ({
        id: order._id,
        date: order.date,
        description: order.desc,
        amount: `$${order.amount}`,
        status: order.status == 'complete' ? 'COMPLETE' : 'PENDING',
      }))
      const orderHeader = ['date', 'id', 'description', 'amount', 'status']

      return { orderHeader, orderData }
    } catch (error) {
      console.error(error)
    }
  }

  const { orderHeader, orderData } = await getOrderData()
  // console.log(JSON.stringify(orderData))
  return { orderHeader, orderData }
}

export function getWalletHistoryAll(userId) {
  const getOrderData = async () => {
    return axios
      .get(baseUrl + `/wallet-data/data?userId=${userId}`, config)
      .then((res) => {
        if (res?.data?.orderHistory) {
          res?.data?.orderHistory.reverse()
        }
        return res.data
      })
  }
  const walletHistory = getOrderData()
  // code to retrieve additional data for the wallet history
  return walletHistory
}

export const addWalletRequest = async (amount, method, userId) => {
  // console.log(amount, method, userId)
  return axios
    .post(
      baseUrl + '/wallet-data/add',
      {
        method,
        amount,
        userId,
      },
      config
    )
    .then((resp) => {
      return resp
    })
    .catch((err) => err)
}

export function createStripeCheckoutSession(data) {
  axios
    .post(`${baseUrl}/stripe/create-checkout-session/`, data, config)
    .then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url
      }
    })
    .catch((err) => {
      // console.log(err)
    })
}

export function createCrypto(data) {
  axios
    .post(`${baseUrl}/crypto/`, data, config)
    .then((res) => {
      // console.log(res)
    })
    .catch((err) => {
      // console.log(err)
    })
}

export function saveOrderDetails(data) {
  axios.post(`${baseUrl}/order/addOrder/`, data, config)
  // .then((response) => console.log(response.data))
  // .catch((error) => console.error(error))
}

export function checkOrderSuccess(id) {
  return axios
    .get(`${baseUrl}/stripe/order/success/${id}/`, config)
    .then((response) => {
      return response.data
    })
    .catch((error) => console.error(error))
}

export function addToCartAPI(body) {
  return axios
    .post(baseUrl + '/cart/add', body, config)
    .then((response) => {
      return response.data
    })
    .catch((error) => console.error(error))
}

export function deleteFromCartAPI(data) {
  return axios
    .post(baseUrl + `/cart/delete`, data, config)
    .then((response) => {
      return response.data
    })
    .catch((error) => console.error(error))
}

export function clearCart(userId) {
  return axios
    .post(baseUrl + '/cart/delete', { userId }, config)
    .then((response) => {
      return response.data
    })
    .catch((error) => console.error(error))
}
