import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Slide from '@mui/material/Slide'
import './header.scss'
import MobileHeader from './mobileHeader'
import AuthUserContext from '../../authentication/AuthUserContext'
import { snackbarNotification } from '../../redux/snackbar_actions'

import { useDispatch, connect } from 'react-redux'
import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
// import { AlertDialog } from "../alertDialogue/AlertDialog";
// import Cookies from "universal-cookie";
import { memo } from 'react'
// import { snackbarNotification } from "../../redux/snackbar.action";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const cookies = new Cookies()

const mapStateToProps = (state) => {
  return {
    cartData: localStorage.getItem('cart')
      ? JSON.parse(localStorage.getItem('cart'))
      : [],
  }
}

const Header = (props) => {
  const location = window.location.href
  // console.log("params",location)
  const navigate = useNavigate()

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [open, setOpen] = React.useState(false)

  // console.log(isLoggedIn, "check login");
  const [userData, setUserData] = useState()
  const [userId, setUserId] = useState()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [telegram, setTelegram] = useState('')

  const authuser = useContext(AuthUserContext)
  // const [cartData,setCartData]=useState(props.cartData);
  const cartData = props.cartData

  // cart Logic

  useEffect(() => {
    if (authuser != null) {
      // console.log(authuser)
      setUserId(authuser._id)
      setUserData(authuser)
      setIsLoggedIn(true)

      // const cart = cookies.get('cart')
      // setCartData(cart ? cart.map((el) => el?.listingId) : [])
      // console.log(cartData);
    } else {
      setIsLoggedIn(false)
    }
  }, [authuser])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const [isOpenAcc, setIsOpenAcc] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const dispatch = useDispatch()

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (userType) => {
    setAnchorElUser(null)
  }
  const handleUserTypeOrderHistory = (userType) => {
    // console.log(userType,"userType")
    setAnchorElUser(null)
    if (userType === 'PUBLISHER') {
      navigate('/wallet-history')
    } else if (userType === 'ADVERTISER') {
      //navigate to order history
      navigate('/order-history')
    }
  }

  const contactUsHandler = () => {
    setAnchorElUser(null)
    const link = 'https://www.koinpr.com/contact'
    window.open(link, '_blank')
  }

  const signOutHandler = () => {
    console.log('here...................')
    cookies.remove('auth-token')
    cookies.remove('cart')

    const data = {
      notificationType: 'success',
      notificationMessage: 'Logged Out Successfully',
    }
    navigate('/')
    window.location.reload()
    dispatch(snackbarNotification(data))
    // setIsLoggedIn(false);
    // navigate("/sign-in");
    // setAnchorElUser(null);
  }

  const handleTelegram = () => {
    setAnchorElUser(null)
    navigate('/add-telegram')
  }

  const handleRedirect = () => {
    if (isLoggedIn) {
      navigate('/')
    } else {
      navigate('/')
    }
  }

  return (
    <>
      <div className="hidden md:block lg:block">
        <div className="Header" onClick={() => setIsOpenAcc(false)}>
          <div className="left" onClick={handleRedirect}>
            <span className="heading">Koinpr</span>
            <span className="subHeading">
              A <b>Todayq</b> Product
            </span>
          </div>
          <div className="right">
            {isLoggedIn ? (
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={() => navigate('/packages')} sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 600,
                      fontSize: '16px',
                      fontFamily: 'Inter',
                    }}
                  >
                    Packages
                  </Typography>
                </IconButton>
              </Box>
            ) : null}
            {isLoggedIn ? (
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={() => navigate('/')} sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 600,
                      fontSize: '16px',
                      fontFamily: 'Inter',
                    }}
                  >
                    Publishers
                  </Typography>
                </IconButton>
              </Box>
            ) : null}
            {isLoggedIn ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open Account">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Typography
                      sx={{
                        color: 'white',
                        fontWeight: 600,
                        fontSize: '16px',
                        marginRight: '21px',
                      }}
                    >
                      My Account
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    sx={{
                      textAlign: 'center',
                      marginLeft: '5px',
                    }}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography onClick={() => navigate('/profile')}>
                      My Profile
                    </Typography>
                  </MenuItem>
                  {userData?.userType === 'PUBLISHER' ? (
                    <MenuItem
                      sx={{
                        textAlign: 'center',
                        marginLeft: '5px',
                      }}
                      onClick={handleCloseUserMenu}
                    >
                      <Typography onClick={() => navigate('/publisherPage')}>
                        My Page
                      </Typography>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )}
                  <MenuItem
                    sx={{
                      textAlign: 'center',
                      marginLeft: '5px',
                    }}
                    onClick={() =>
                      handleUserTypeOrderHistory(userData?.userType)
                    }
                  >
                    <Typography>
                      {userData?.userType === 'ADVERTISER'
                        ? 'Order History'
                        : userData?.userType === 'PUBLISHER'
                        ? 'Wallet History'
                        : null}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      textAlign: 'center',
                      marginLeft: '5px',
                    }}
                    onClick={handleTelegram}
                  >
                    <Typography>Telegram</Typography>
                  </MenuItem>
                  {/* {userData?.userType === 'PUBLISHER' ? (
                    <MenuItem
                      sx={{
                        textAlign: 'center',
                        marginLeft: '5px',
                      }}
                      onClick={handleCloseUserMenu}
                    >
                      <Typography>
                        <Link to="/add-listing">Add Listing</Link>
                      </Typography>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )} */}

                  <MenuItem
                    sx={{
                      textAlign: 'center',
                      marginLeft: '5px',
                    }}
                    onClick={contactUsHandler}
                  >
                    <Typography>Contact Us</Typography>
                  </MenuItem>

                  <MenuItem
                    sx={{
                      textAlign: 'center',
                      marginLeft: '5px',
                    }}
                    onClick={signOutHandler}
                  >
                    <Typography>Sign Out</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <span>
                {location.split('/')[location.split('/').length - 1] ===
                'sign-up' ? (
                  <>
                    <Link to="/packages" style={{ padding: '0 30px' }}>
                      Packages
                    </Link>
                    <Link to="/" style={{ padding: '0 30px' }}>
                      Publishers
                    </Link>
                    <Link to="/sign-in" style={{ padding: '0 30px' }}>
                      Log In
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/packages" style={{ padding: '0 30px' }}>
                      Packages
                    </Link>
                    <Link to="/" style={{ padding: '0 30px' }}>
                      Publishers
                    </Link>
                    <Link to="/sign-up" style={{ padding: '0 30px' }}>
                      Sign Up
                    </Link>
                  </>
                )}
              </span>
            )}

            {isLoggedIn && userData?.userType === 'ADVERTISER' ? (
              <IconButton
                onClick={() => navigate('/cart-checkout')}
                aria-label="cart"
              >
                <StyledBadge badgeContent={cartData?.length} color="primary">
                  <ShoppingCartIcon
                    sx={{ color: 'white', marginLeft: '-16px' }}
                  />
                </StyledBadge>
              </IconButton>
            ) : null}
            <a
              className="getInTouch"
              href="https://koinpr.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get in touch
            </a>
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }} className="md:hidden lg:hidden sm:block">
        <MobileHeader
          handleTelegram={handleTelegram}
          badgeContent={cartData?.length}
          contactUsHandler={contactUsHandler}
        />
      </div>
    </>
  )
}

export default connect(mapStateToProps)(memo(Header))
