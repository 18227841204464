import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Dropdown.scss'
import { useEffect } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

const MultiSelectDropdown = ({
  options,
  onChange,
  placeholder,
  label,
  isOpen,
  onOpenClose,
  selectedRegions,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  useEffect(() => {
    setSelectedOptions(selectedRegions)
  }, [selectedRegions])

  const onOptionClicked = (value) => () => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(value)) {
        onChange(prevSelectedOptions.filter((option) => option !== value))
        return prevSelectedOptions.filter((option) => option !== value)
      } else {
        onChange([...prevSelectedOptions, value])
        return [...prevSelectedOptions, value]
      }
    })
    onOpenClose()
  }

  const displayValue = () => {
    if (selectedOptions.length === 0) return placeholder
    // if (isOpen) return selectedOptions.join(', ')
    return `${selectedOptions.length} Selected`
  }

  return (
    <div className="Dropdown">
      <label>{label}</label>
      <div
        className="Dropdown__header"
        onClick={onOpenClose}
        style={{
          border: isOpen ? '2px solid rgb(108 156 255)' : '2px solid black',
          color: isOpen ? 'rgb(83,81,73)' : 'black',
        }}
      >
        {displayValue()}
        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {isOpen && (
        <div className="Dropdown__list-container">
          <div className="Dropdown__list">
            {options.map((option) => (
              <div
                onClick={onOptionClicked(option)}
                className={`Dropdown__list-option ${
                  selectedOptions.includes(option) ? 'selected' : ''
                }`}
                key={option}
              >
                {option.name} {selectedOptions.includes(option) ? '✅' : ''}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

MultiSelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
}

MultiSelectDropdown.defaultProps = {
  placeholder: 'Select...',
}

export default MultiSelectDropdown
