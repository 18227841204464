import { actionType } from './type'
import Cookies from 'universal-cookie'
// import localStorage from 'localStorage'

const localStorage = window.localStorage;

const initialState = {
  products: [],
  authenticatedUserDetails: {},
  token: {},
  userData: {},
  total: 0,
  orderDetailsData: [],
}

export const CartReducers = (state = initialState, action) => {
  const cookies = new Cookies()
  const { type, payload } = action

  switch (type) {
    case actionType.ADD_TO_CART:
      const { type,packageId,listingId } = payload
      console.log(packageId)
      console.log(listingId)
      console.log(type)
      const doesItemExist = state?.products?.find((item) => type=="package"?item.packageId === packageId:item.listingId === listingId)

      if (doesItemExist) {
        return {
          ...state,
        }
      } else {
        const newProducts = [
          ...(localStorage.getItem('cart')?JSON.parse(localStorage.getItem('cart')):state.products),
          payload,
        ]
        // console.log(newProducts);

        const newState = {
          ...state,
          products: newProducts,
          total: newProducts.length,
        }

        console.log(newProducts)

        // cookies.set('cart', newProducts, { path: '/' })
        localStorage.setItem('cart', JSON.stringify(newProducts));
        return newState
      }

    case actionType.ACTIVATE_CART:
      return {
        ...state,
      }

    case actionType.REMOVE_FROM_CART:
      const cartProducts = localStorage.getItem('cart')?JSON.parse(localStorage.getItem('cart')):state.products
      const item = action.id
      console.log(item)
      console.log(cartProducts)
      let indexToRemove=-1
      if(item.type=="package"){
        indexToRemove = cartProducts.findIndex(
          (product) => product.type === "package" && product.packageId==item.packageId
        )
      }
      else if(item.type=="listing"){
        indexToRemove = cartProducts.findIndex(
          (product) => product.type === "listing" && product.listingId==item.listingId
        )
      }

      console.log(indexToRemove)

      if (indexToRemove !== -1) {
        cartProducts.splice(indexToRemove, 1)
      }

      console.log(cartProducts)

      // cookies.set('cart', cartProducts, { path: '/' })
      localStorage.setItem('cart', JSON.stringify(cartProducts));

      return {
        ...state,
        products: cartProducts
        // .map((product) =>
        //   product.id === action.id
        //     ? { ...product, selected: false, quantity: 1 }
        //     : product
        // ),
      }

    case actionType.ADD_QUANTITY:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.id
            ? { ...product, quantity: product.quantity + 1 }
            : product
        ),
      }
    case actionType.SUB_QUANTITY:
      return {
        ...state,
        total: state.total - 1,
        products: state.products.filter((item) => {
          return item.id != payload.id
        }),
      }
    case actionType.EMPTY_CART:
      localStorage.setItem('cart', JSON.stringify([]));
      // cookies.set('cart', [], { path: '/' })
      return {
        ...state
      }
    case actionType.AUTHENTICATED_USER_DETAILS:
      return {
        ...state,
        authenticatedUserDetails: payload,
      }
    case actionType.ORDER_DETAILS:
      return {
        ...state,
        orderDetailsData: [...state.orderDetailsData, payload],
      }
    case actionType.SET_TOKEN_TO_REDUX:
      return {
        ...state,
        token: payload.token,
        authenticatedUserDetails: payload?.data,
      }
    case actionType.SET_USER_DATA_TO_REDUX:
      return {
        ...state,
        userData: payload.data,
        authenticatedUserDetails: payload?.data,
      }

    default:
      return state
  }
}
