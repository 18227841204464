import { useNavigate } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Grid, Button } from '@mui/material'
import './uploadDocuments.scss'
import { Constant } from '../../common'
import { useState, useEffect } from 'react'
import { getUserByToken, saveDocuments } from '../../service/api'
import Loader from '../Loader/Loader'
import { snackbarNotification } from '../../redux/snackbar_actions.js'
import { useDispatch } from 'react-redux'

function UploadDocuments(props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const [authUser, setAuthUser] = useState(null)
  const [documentType, setDocumentType] = useState('')
  const [document, setDocument] = useState(null)

  useEffect(() => {
    ;(async () => {
      const response = await getUserByToken()
      setAuthUser(response)
    })()
  }, [])

  const handleUploadChange = (event) => {
    setDocument(event.target.files[0])
    event.target.value = ''
  }

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value)
  }

  const handleSubmit = async () => {
    if (document?.size > Constant.FILE_SIZE) {
      dispatch(
        snackbarNotification({
          notificationType: 'error',
          notificationMessage: 'File size is greater than 5MB',
        })
      )
      setDocument(null)
      return
    }

    const data = new FormData()
    data.append('image', document, document.name)
    setLoading(true)
    const response = await saveDocuments(data)
    setLoading(false)
    if (authUser?.userType == Constant.PUBLISHER) {
      props.handleClick(Constant.WITHDRAWL_OPTION)
    } else {
      navigate('/')
    }
  }

  const checkButtonToDisable = () => {
    if (authUser?.userVerified) {
      return true
    }

    if (!document || !documentType) {
      return true
    }
    return false
  }

  return (
    <>
      {loading && <Loader />}
      <Grid xs={12}>
        <div className="uploadDocuments">
          <Grid item xs={12} className="head">
            <p>Upload Documents</p>
          </Grid>
          <Grid item xs={12} className="status">
            <p>
              Please upload the required documents below in order to validate
              your Identification Details.
            </p>
          </Grid>
          {/* <Grid sx={{ marginTop: '40px' }} item xs={12} className="dropdown">
            <FormControl
              sx={{
                width: '251px',
                border: '1px solid black',
                '& .MuiSelect-root:focus': {
                  outline: 'none',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 'none',
                  },
              }}
              size="small"
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => handleDocumentTypeChange(e)}
                value={documentType}
                name="documentType"
                label="Document Type"
                displayEmpty
                renderValue={() =>
                  documentType ? documentType : 'Choose Document Type'
                }
                required
              >
                {Constant.DOCUMENT_LIST.map((key, idx) => {
                  return (
                    <MenuItem value={Constant.DOCUMENT_LIST[idx]}>
                      {Constant.DOCUMENT_LIST[idx]}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid> */}

          <div className="dropdown-container">
            <select
              className="dropdown"
              value={documentType}
              onChange={(e) => handleDocumentTypeChange(e)}
              style={{
                appearance: 'none',
                paddingRight: '10px',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '15px',
                color: '#000000',
                padding: '11px 15px',
              }}
            >
              <option value="">Choose Document type</option>
              <option value="Government Issued ID">Government Issued ID</option>
              <option value="Pan card">Passport</option>
              <option value="Driving license">Driving license</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              sx={{
                color: 'black',
                marginTop: '20px',
                textTransform: 'none',
                border: '1px solid black',
              }}
              component="label"
            >
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: '500',
                  fontFamily: 'Inter,sans-serif',
                }}
              >
                Upload File +
              </span>
              <input
                hidden
                accept=".jpeg, .png, .pdf, .doc"
                type="file"
                name="image"
                onChange={(e) => handleUploadChange(e)}
                disabled={authUser?.userVerified}
              />
            </Button>
          </Grid>

          <Grid item xs={14} className="pBottom">
            <p>
              Max file size 5 MB. Supported file types: png, jpeg, pdf, doc.
            </p>
          </Grid>

          <Grid item xs={12}>
            <button
              className="submitButton"
              type="submit"
              onClick={handleSubmit}
              disabled={checkButtonToDisable()}
            >
              Proceed
              <ArrowForwardIcon sx={{ fontSize: '20px' }} />
            </button>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export default UploadDocuments
