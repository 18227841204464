import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import './identificationDetails.scss'
import { Constant } from '../../common.js'
import { getUserByToken, saveUserDetails } from '../../service/api'
import Loader from '../Loader/Loader'
function IdentificationDetails(props) {
  const [authUser, setAuthUser] = useState(null)
  const [companyName, setCompanyName] = useState('')
  const [companyIdentificationNumber, setCompanyIdentificationNumber] =
    useState('')
  const [companyCountry, setCompanyCountry] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [loading, setLoading] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('')
  const [address, setAddress] = useState('')

  const [representCompany, setRepresentCompany] = useState(false)

  useEffect(() => {
    ;(async () => {
      const response = await getUserByToken()
      setAuthUser(response)
    })()
  }, [])

  useEffect(() => {
    if (authUser?.representCompany) {
      setRepresentCompany(true)

      if (authUser?.companyName) {
        setCompanyName(authUser.companyName)
      }
      if (authUser?.address) {
        setCompanyAddress(authUser.address)
      }
      if (authUser?.companyId) {
        setCompanyIdentificationNumber(authUser.companyId)
      }
      if (authUser?.country) {
        setCompanyCountry(authUser.country)
      }
    } else {
      if (authUser?.firstName) {
        setFirstName(authUser.firstName)
      }
      if (authUser?.lastName) {
        setLastName(authUser.lastName)
      }
      if (authUser?.address) {
        setAddress(authUser.address)
      }
      if (authUser?.countryOfResidency) {
        setCountry(authUser.countryOfResidency)
      }
    }
  }, [authUser])

  const handleChange = (key, event) => {
    switch (key) {
      case Constant.COMPANY_NAME:
        setCompanyName(event.target.value)
        break
      case Constant.COMPANY_IDENTIFICATION_NUMBER:
        setCompanyIdentificationNumber(event.target.value)
        break
      case Constant.COMPANY_COUNTRY:
        setCompanyCountry(event.target.value)
        break
      case Constant.COMPANY_ADDRESS:
        setCompanyAddress(event.target.value)
        break
      case Constant.FIRST_NAME:
        setFirstName(event.target.value)
        break
      case Constant.LAST_NAME:
        setLastName(event.target.value)
        break
      case Constant.COUNTRY:
        setCountry(event.target.value)
        break
      case Constant.ADDRESS:
        setAddress(event.target.value)
        break
    }
  }

  const handleClick = () => {
    const valueOfRepresentCompany = representCompany
    setRepresentCompany(!valueOfRepresentCompany)
  }

  const getPayload = () => {
    if (representCompany) {
      return {
        companyName: companyName,
        companyId: companyIdentificationNumber,
        country: companyCountry,
        address: companyAddress,
        representCompany: representCompany,
      }
    }
    return {
      firstName: firstName,
      lastName: lastName,
      countryOfResidency: country,
      address: address,
      representCompany: representCompany,
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    const request = getPayload()
    const response = await saveUserDetails(authUser._id, request)
    setAuthUser(response)
    props.handleClick(Constant.UPLOAD_DOCUMENTS)
    setLoading(false)
  }

  const checkButtonToDisable = () => {
    if (authUser?.userVerified) {
      return true
    }

    if (representCompany) {
      if (
        !companyName.trim() ||
        !companyIdentificationNumber.trim() ||
        !companyAddress.trim() ||
        !companyCountry.trim()
      ) {
        return true
      }
      return false
    } else {
      if (
        !firstName.trim() ||
        !lastName.trim() ||
        !country.trim() ||
        !address.trim()
      ) {
        return true
      }
      return false
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Grid>
        <div className="identificationDetails">
          <Grid item xs={12} className="head">
            <p sx={{ fontWeight: '600', fontSize: '18px' }}>
              Identification Details
            </p>
          </Grid>
          <Grid item xs={12} className="status">
            <p>Your details will be used for billing and generating invoice</p>
          </Grid>
          <Grid sx={{ marginTop: '20px' }} item md={9} xs={12}>
            <div className="checkBox">
              <label className="label">I represent a company</label>
              <input
                id="representCompany"
                type="radio"
                name="representCompany"
                className="round"
                onClick={handleClick}
                checked={representCompany}
              />
            </div>
          </Grid>
          <Grid item xs={12} className="status">
            <p sx={{ fontWeight: '500', fontSize: '14px' }}>
              Enter your details
            </p>
          </Grid>
          {representCompany ? (
            <Grid container columnSpacing={1} sx={{ marginTop: '0px' }}>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  placeholder="Company Name"
                  type="input"
                  name="account"
                  value={companyName}
                  disabled={authUser?.userVerified}
                  className="input"
                  style={{ width: '85%' }}
                  onChange={(e) => handleChange(Constant.COMPANY_NAME, e)}
                />
              </Grid>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  className="input"
                  type="input"
                  name="account"
                  value={companyIdentificationNumber}
                  disabled={authUser?.userVerified}
                  placeholder="Company Identification No."
                  style={{ width: '85%' }}
                  onChange={(e) =>
                    handleChange(Constant.COMPANY_IDENTIFICATION_NUMBER, e)
                  }
                />
              </Grid>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  className="input"
                  placeholder="Country"
                  type="input"
                  value={companyCountry}
                  disabled={authUser?.userVerified}
                  name="account"
                  style={{ width: '85%' }}
                  onChange={(e) => handleChange(Constant.COMPANY_COUNTRY, e)}
                />
              </Grid>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  className="input"
                  placeholder="Address"
                  type="input"
                  value={companyAddress}
                  disabled={authUser?.userVerified}
                  name="account"
                  style={{ width: '85%' }}
                  onChange={(e) => handleChange(Constant.COMPANY_ADDRESS, e)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container columnSpacing={1} sx={{ marginTop: '0px' }}>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  placeholder="First Name"
                  type="input"
                  name="account"
                  value={firstName}
                  disabled={authUser?.userVerified}
                  className="input"
                  style={{ width: '80%' }}
                  onChange={(e) => handleChange(Constant.FIRST_NAME, e)}
                />
              </Grid>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  className="input"
                  type="input"
                  name="account"
                  value={lastName}
                  disabled={authUser?.userVerified}
                  placeholder="Last Name"
                  style={{ width: '80%' }}
                  onChange={(e) => handleChange(Constant.LAST_NAME, e)}
                />
              </Grid>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  className="input"
                  placeholder="Country of Residence"
                  type="input"
                  value={country}
                  disabled={authUser?.userVerified}
                  name="account"
                  style={{ width: '80%' }}
                  onChange={(e) => handleChange(Constant.COUNTRY, e)}
                />
              </Grid>
              <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                <input
                  className="input"
                  placeholder="Address"
                  type="input"
                  value={address}
                  disabled={authUser?.userVerified}
                  name="account"
                  style={{ width: '80%' }}
                  onChange={(e) => handleChange(Constant.ADDRESS, e)}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <button
              className="submitButton"
              type="submit"
              onClick={handleSubmit}
              disabled={checkButtonToDisable()}
            >
              Proceed
              <ArrowForwardIcon
                sx={{
                  fontSize: '20px',
                  marginLeft: '5px',
                  marginBottom: '3px',
                }}
              />
            </button>
          </Grid>
          <Grid item xs={12} className="pBottom">
            <p>
              Please make sure that the details you enter here matches the
              documents you will be providing for verification.
            </p>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export default IdentificationDetails
