import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import './accountDetails.scss'
import WithdrawlOption from '../WithdrawlOption/withdrawlOption'
import IdentificationDetails from '../IdentificationDetails/identificationDetails'
import UploadDocuments from '../UploadDocuments/uploadDocuments'
import { Constant } from '../../common'
import { getUserByToken } from '../../service/api'
import useMediaQuery from '@mui/material/useMediaQuery'

function AccountDetails() {
  const matches = useMediaQuery('(min-width:600px)')
  const match = useMediaQuery('(min-width:1200px)')

  const [identificationDetails, setIdentificationDetails] = useState(true)
  const [uploadDocuments, setUploadDocuments] = useState(false)
  const [withdrawlOption, setWithdrawlOption] = useState(false)
  const [authUser, setAuthUser] = useState(null)

  useEffect(() => {
    ;(async () => {
      const response = await getUserByToken()
      setAuthUser(response)
    })()
  }, [])

  const handleClick = (key) => {
    switch (key) {
      case Constant.IDENTIFICATION_DETAILS:
        setIdentificationDetails(true)
        setUploadDocuments(false)
        setWithdrawlOption(false)
        break
      case Constant.UPLOAD_DOCUMENTS:
        setIdentificationDetails(false)
        setUploadDocuments(true)
        setWithdrawlOption(false)
        break
      case Constant.WITHDRAWL_OPTION:
        setIdentificationDetails(false)
        setUploadDocuments(false)
        setWithdrawlOption(true)
        break
    }
  }

  return (
    <Grid container>
      <Grid
        xs={12}
        md={5}
        lg={4}
        padding={match ? '50px' : matches ? '20px' : '20px'}
        item
      >
        <div className="accountDetails">
          <Grid item xs={12} className="head">
            <p sx={{ fontWeight: '600', fontSize: '22px' }}>Account Details</p>
          </Grid>
          <Grid item xs={12} className="status">
            <p>
              Current Status :{' '}
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#1a94ba',
                }}
              >
                {authUser?.userVerified ? 'Verified' : 'Not Verified'}
              </span>
            </p>
          </Grid>
          <form>
            <Grid
              sx={{
                marginTop: '40px',
                paddingLeft: '39px',
              }}
              item
              xs={12}
              className="input"
            >
              <label className="label">Identification Details</label>
              <input
                value="1"
                type="radio"
                name="account"
                className="round"
                onChange={() => handleClick(Constant.IDENTIFICATION_DETAILS)}
                checked={identificationDetails}
              />
            </Grid>
            <Grid sx={{ marginTop: '15px' }} item xs={12} className="input">
              <label className="label">Upload Documents</label>
              <input
                value="1"
                type="radio"
                name="account"
                className="round"
                onChange={() => handleClick(Constant.UPLOAD_DOCUMENTS)}
                checked={uploadDocuments}
              />
            </Grid>
            {authUser?.userType == Constant.PUBLISHER && (
              <Grid sx={{ marginTop: '10px' }} item xs={12} className="input">
                <label className="label">Withdrawal Options</label>
                <input
                  id="xys"
                  type="radio"
                  name="account"
                  className="round"
                  onChange={() => handleClick(Constant.WITHDRAWL_OPTION)}
                  checked={withdrawlOption}
                />
              </Grid>
            )}
          </form>
        </div>
      </Grid>

      {identificationDetails && (
        <Grid
          xs={12}
          sm={12}
          md={7}
          lg={8}
          item
          sx={{ padding: { xs: '20px', md: '20px', lg: '50px' } }}
        >
          <IdentificationDetails handleClick={handleClick} />
        </Grid>
      )}
      {uploadDocuments && (
        <Grid
          xs={12}
          md={7}
          lg={8}
          item
          sx={{ padding: { xs: '20px', md: '20px', lg: '50px' } }}
        >
          <UploadDocuments handleClick={handleClick} />
        </Grid>
      )}
      {authUser?.userType == Constant.PUBLISHER && withdrawlOption && (
        <Grid
          xs={12}
          md={7}
          lg={8}
          item
          sx={{ padding: { xs: '20px', md: '20px', lg: '50px' } }}
        >
          <WithdrawlOption />
        </Grid>
      )}
    </Grid>
  )
}

export default AccountDetails
