import { combineReducers,  } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
import { CartReducers } from "./reducer";
import { snackbarNotificationReducer } from './snackbar_reducer'

const reducer = combineReducers({
  cart: CartReducers,
  snackbarNotification: snackbarNotificationReducer,
});

const store = configureStore({
  reducer,
  composeWithDevTools,
});

export default store;
