import React, { useEffect, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import './checkoutSuccess.css'
import {
  checkOrderSuccess,
  saveOrderDetails,
  clearCart,
} from '../../../service/api'
import { emptyCart } from '../../../redux/actions'
import AuthUserContext from '../../../authentication/AuthUserContext'
import Cookies from 'universal-cookie'
import styled from 'styled-components'

const cookies = new Cookies()

const mapStateToProps = (state) => {
  return {
    cartData: localStorage.getItem('cart')?JSON.parse(localStorage.getItem('cart')):[],
  }
}

const CheckoutSuccess = (props) => {
  const authUser = useContext(AuthUserContext)

  const dispatch = useDispatch()

  const cartData = props.cartData

  let finalprice = 0
  for (const { price } of cartData) {
    finalprice += price
  }

  const { id } = useParams()

  const shouldCallHanldeSuccess = useRef(true)

  const handleGetOrderName = (name) => {
    let idx = -1
    let spaces = 0

    for (let i = 0; i < name.length; i++) {
      if (name[i] == ' ') {
        spaces = spaces + 1
      }

      if (spaces == 3) {
        idx = i + 1
        break
      }
    }

    if (idx != -1) {
      return name.substring(idx)
    }

    return name
  }

  const getCategory = (name) => {
    let category = ''

    for (let i = 0; i < name.length; i++) {
      if (name[i] == ' ') {
        break
      }
      category += name[i]
    }

    if (category.toLowerCase() == 'press') {
      return 'Press Release'
    }

    if (category.toLowerCase() == 'sponsored') {
      return 'Sponsored Article'
    }

    if (category.toLowerCase() == 'button') {
      return 'Button Ads'
    }

    if (category.toLowerCase() == 'banner') {
      return 'Banner Ads'
    }
  }

  const getProductsName = () => {
    let description = 'Placed Order for '

    // const categoryWiseOrder = {}


    for (let i=0;i<cartData.length-1;i++) {
      description = description + cartData[i].name+', '
    }
    description+=cartData[cartData.length-1].name

    // Object.keys(categoryWiseOrder).forEach((key, idx) => {
    //   description += key + ' ('
    //   for (let i = 0; i < categoryWiseOrder[key].length; i++) {
    //     description += categoryWiseOrder[key][i]
    //     if (i != categoryWiseOrder[key].length - 1) {
    //       description += ', '
    //     }
    //   }
    //   description += ') '
    // })

    return description
  }

  const handleSuccess = async (orderStatus) => {
    const data = {
      desc: getProductsName(),
      amount: finalprice,
      userId: authUser?._id,
      status: orderStatus,
    }
    await saveOrderDetails(data)
    clearCart(authUser?._id)
    dispatch(emptyCart())
  }

  useEffect(() => {
    ;(async () => {
      if (shouldCallHanldeSuccess.current && authUser && cartData?.length) {
        shouldCallHanldeSuccess.current = false
        let response = await checkOrderSuccess(id)
        if (response.success) {
          handleSuccess(response.status)
        }
      }
    })()
  }, [authUser])

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 105px;
  `

  const Title = styled.h1`
    width: 441px;
    height: 59px;
    font-size: 25px;
    font-weight: 600;
    font-family: Inter;
    text-align: center;
    margin-bottom: 9px;
    color: #000000;
  `

  const Message = styled.p`
    width: 509px;
    height: 79px;
    font-size: 18px;
    font-weight: 600;
    font-family: Inter;
    text-align: center;
    margin-top: 9px;
    color: #000000;
  `

  return (
    <Container>
      <Title>Thanks for your order</Title>
      <Message>
        Your order has been initiated and we are confirming the payment. You
        will receive a confirmation email shortly.
        <br />
        <br />
        For any queries contact support.
      </Message>
    </Container>
  )
}

export default connect(mapStateToProps)(CheckoutSuccess)
