import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '../listing/ppage.scss'
import '../package/packagePage.scss'

import { Avatar } from '@mui/material'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useState, useContext } from 'react'
import AuthUserContext from '../../authentication/AuthUserContext'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../redux/actions'
import { removeFromCart } from '../../redux/actions'
import { getPublisherById } from '../../service/api'
import { useParams } from 'react-router-dom'
import { addToCartAPI, deleteFromCartAPI } from '../../service/api.js'
import { useMediaQuery } from '@mui/material'
import '../Marketplace/marketplace.scss'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NormalCard from './normalCard'
import ImageCard from './imageCard'
import Loader from '../Loader/Loader'

function Listing() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const elementId = searchParams.get('elementId')
  console.log(elementId)
  const [impressions, setImpressions] = useState({})

  const [loading, setLoading] = useState(false)
  // const cooko = new Cookies()
  const authiUsers = useContext(AuthUserContext)
  const navigats = useNavigate()

  const mobQuery = useMediaQuery("(max-width:'480px')")

  const styles = {
    root: {
      paddingLeft: 540,
      paddingRight: 70,
    },
    clickableText: {
      fontSize: mobQuery ? 16 : 14,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
    },
    pageText: {
      fontSize: mobQuery ? 14 : 12,
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: 14,
      marginRight: mobQuery ? 10 : 4,
      marginLeft: mobQuery ? 10 : 4,
    },
  }

  const authUser = useContext(AuthUserContext)

  const handleDropdownChange = (selectedOption) => {
    console.log(selectedOption)
  }

  const [displayData, setDisplayData] = useState({})
  const { id } = useParams()

  console.log(id)
  useEffect(() => {
    ;(async () => {
      const response = await getPublisherById(id)
      console.log('publishers', response)
      setDisplayData(response)
    })()
  }, [id])

  useEffect(() => {
    if (elementId) {
      const element = document.getElementById(elementId)
      console.log('eeeeeeeeeeeeeeeee', element)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [displayData])

  const tableData = [
    {
      header: 'Website',
      value: displayData?.websiteLink
        ? displayData?.websiteLink
        : 'Data Not Available',
      bool: displayData?.websiteLink ? 'true' : 'false',
    },
    {
      header: 'Visitors',
      value: displayData?.visitors
        ? displayData?.visitors
        : 'Data Not Available',
      bool: displayData?.visitors ? 'true' : 'false',
    },
    {
      header: 'Twitter',
      value: displayData?.twitter ? displayData?.twitter : 'Data Not Available',
      bool: displayData?.twitter ? 'true' : 'false',
    },
    {
      header: 'Facebook',
      value: displayData?.facebook
        ? displayData?.facebook
        : 'Data Not Available',
      bool: displayData?.facebook ? 'true' : 'false',
    },
    {
      header: 'LinkedIn',
      value: displayData?.linkedin
        ? displayData?.linkedin
        : 'Data Not Available',
      bool: displayData?.linkedin ? 'true' : 'false',
    },
    {
      header: 'Regions',
      value:
        displayData?.regions == null
          ? ''
          : displayData?.regions.map((region) => region.name).join(', '),
      bool: displayData?.regions ? 'true' : 'false',
    },
  ]

  const cartData = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null
  const cartDataIds = cartData ? cartData.map((el) => el?.id) : []

  const dispatch = useDispatch()
  const [isItemInCart, setIsItemInCart] = useState(false)

  const handleAddToCart = async () => {
    const { _id, details, image, price } = displayData

    if (cartDataIds.includes(_id)) {
      alert('Already added to the cart')
      return
    }

    const payload = {
      id: _id,
      name: displayData?.offerTitle,
      image,
      price,
      quantity: 1,
    }

    dispatch(addToCart(payload))
    setIsItemInCart(true)

    const body = {
      listingId: _id,
      name: displayData?.offerTitle,
      image,
      price,
      userId: authUser._id,
    }
    const data = await addToCartAPI(body)
    // console.log(data)
  }

  const handleRemoveFromCart = async (item) => {
    dispatch(removeFromCart(item))
    setIsItemInCart(false)
    // console.log(item)
    const data = await deleteFromCartAPI(authUser._id, item._id)
    // console.log(data)
  }

  useEffect(() => {
    setIsItemInCart(cartDataIds.includes(displayData?._id))
  }, [cartDataIds, displayData])

  const listingData = {
    displayData: displayData,
    tableData: tableData,
  }

  const publisherImage = displayData.image
  // setPublisherName(displayData.companyName)
  const publisherName = displayData.companyName

  return (
    <div className="ppContainer">
      {loading && <Loader />}
      <Link to="/">
        <div className="continue">
          <ArrowBackIcon />
          <h1>Continue Shopping</h1>
        </div>
      </Link>
      <Grid container xs={12} spacing={4} className="gridPP">
        <Grid item xs={12} md={6} className="gridPPinside">
          <div className="left">
            <div className="headExp">
              <span className="image">
                <Avatar
                  sx={{ width: '65px', height: '65px' }}
                  src={displayData.image}
                />
              </span>
              <span className="listTit">
                {listingData?.displayData?.companyName}
              </span>
            </div>
            <span className="data">
              <span className="listTitR" style={{ display: 'none' }}>
                {listingData?.displayData?.companyName}
              </span>
              {listingData.displayData.description !== '' ? (
                <>{listingData.displayData.description}</>
              ) : (
                <>Description Not available</>
              )}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="gridPPinside">
          <div className="right">
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
              }}
            >
              {listingData.tableData.map((item, index) =>
                item.bool == 'true' ? (
                  <tr key={index}>
                    <th
                      style={{
                        border: '1.5px solid black',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        width: '30%',
                        padding: '10px',
                        backgroundColor: 'white',
                        fontWeight: '500',
                      }}
                    >
                      {item.header}
                    </th>
                    <td
                      style={{
                        border: '1.5px solid black',
                        paddingLeft: '30px',
                        width: '70%',
                        padding: '10px',
                        fontWeight: '400',
                      }}
                    >
                      <p>{item.value}</p>
                    </td>
                  </tr>
                ) : null
              )}

              {(displayData?.other ? displayData.other : []).map(
                (item, index) => (
                  <tr key={index}>
                    <th
                      style={{
                        border: '1.5px solid black',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        width: '30%',
                        padding: '10px',
                        backgroundColor: 'white',
                        fontWeight: '500',
                      }}
                    >
                      {item?.title}
                    </th>
                    <td
                      style={{
                        border: '1.5px solid black',
                        paddingLeft: '30px',
                        width: '70%',
                        padding: '10px',
                        fontWeight: '400',
                      }}
                    >
                      {item?.value}
                    </td>
                  </tr>
                )
              )}
            </table>
          </div>
        </Grid>

        {listingData.displayData.listings == null ? (
          <div></div>
        ) : (
          listingData.displayData.listings.map((listing) => (
            <>
              <Grid
                item
                xs={12}
                md={6}
                className="gridPPinside"
                id={listing._id}
              >
                {listing.offeringCategory == 'adsOffering' ? (
                  <ImageCard
                    listing={listing}
                    impressions={impressions[listing._id] || 0}
                    setImpressions={setImpressions}
                    publisherImage={publisherImage}
                    publisherName={publisherName}
                  />
                ) : (
                  <NormalCard
                    listing={listing}
                    publisherImage={publisherImage}
                    publisherName={publisherName}
                  />
                )}
              </Grid>

              {listing.offeringCategory == 'adsOffering' &&
              listing.minPrice != 0 &&
              listing.fixedPrice == 0 ? (
                <Grid item xs={12} md={6} className="gridPPinside">
                  <div className="right">
                    <div
                      style={{
                        overflow: 'hidden',
                      }}
                    >
                      <table
                        style={{
                          width: '100%',
                          borderCollapse: 'collapse',
                        }}
                      >
                        <tbody>
                          {[
                            // {
                            //   header: 'CPM',
                            //   value:
                            //     listing?.cpm == undefined
                            //       ? 'Data Not Available'
                            //       : listing?.cpm == ''
                            //       ? 'Data Not Available'
                            //       : listing?.cpm,
                            // },
                            {
                              header: 'Impressions',
                              value:
                                impressions[listing._id] == undefined
                                  ? 'Data Not Available'
                                  : impressions[listing._id] == ''
                                  ? 'Data Not Available'
                                  : impressions[listing._id],
                            },
                          ].map((item, index) =>
                            item.value != 'Data Not Available' ? (
                              <tr key={index}>
                                <th
                                  style={{
                                    border: '1.5px solid black',
                                    textAlign: 'left',
                                    paddingLeft: '30px',
                                    width: '30%',
                                    padding: '10px',
                                    backgroundColor: 'white',
                                    fontWeight: '500',
                                    // borderRadius: '5px 0 0 5px',
                                  }}
                                >
                                  {item.header}
                                </th>
                                <td
                                  style={{
                                    border: '1.5px solid black',
                                    paddingLeft: '30px',
                                    width: '70%',
                                    padding: '10px',
                                    fontWeight: '400',
                                    // borderRadius: '0 5px 5px 0',
                                  }}
                                >
                                  {item.value}
                                </td>
                              </tr>
                            ) : null
                          )}

                          {(listing?.other ? listing.other : []).map(
                            (item, index) => (
                              <tr key={index}>
                                <th
                                  style={{
                                    border: '1.5px solid black',
                                    textAlign: 'left',
                                    paddingLeft: '30px',
                                    width: '30%',
                                    padding: '8px',
                                    backgroundColor: 'white',
                                    fontWeight: '500',
                                    // borderRadius: '5px 0 0 5px',
                                  }}
                                >
                                  {item?.title}
                                </th>
                                <td
                                  style={{
                                    border: '1.5px solid black',
                                    paddingLeft: '30px',
                                    width: '70%',
                                    padding: '8px',
                                    fontWeight: '400',
                                    // borderRadius: '0 5px 5px 0',
                                  }}
                                >
                                  {item?.value}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} className="gridPPinside">
                  <div className="right">
                    <div
                      style={{
                        overflow: 'hidden',
                      }}
                    >
                      <table
                        style={{
                          width: '100%',
                          borderCollapse: 'collapse',
                        }}
                      >
                        <tbody>
                          {[
                            {
                              header: 'Social Share',
                              value: listing?.socialShare
                                ? listing?.socialShare
                                : 'Data Not Available',
                            },
                            {
                              header: 'Google News',
                              value: listing?.googleNews
                                ? listing?.googleNews
                                : 'Data Not Available',
                            },
                            {
                              header: 'Indexed Article',
                              value: listing?.indexedArticle
                                ? listing?.indexedArticle
                                : 'Data Not Available',
                            },
                            {
                              header: 'Link Type',
                              value:
                                listing?.linkType == undefined
                                  ? 'Data Not Available'
                                  : listing?.linkType == ''
                                  ? 'Data Not Available'
                                  : listing?.linkType == 'noFollowLink'
                                  ? 'No follow'
                                  : listing?.linkType == 'doFollowLink'
                                  ? 'Do follow'
                                  : listing?.linkType == 'noLink'
                                  ? 'No Link'
                                  : listing?.linkType,
                            },
                          ].map((item, index) =>
                            item.value != 'Data Not Available' ? (
                              <tr key={index}>
                                <th
                                  style={{
                                    border: '1.5px solid black',
                                    textAlign: 'left',
                                    paddingLeft: '30px',
                                    width: '30%',
                                    padding: '10px',
                                    backgroundColor: 'white',
                                    fontWeight: '500',
                                    // borderRadius: '5px 0 0 5px',
                                  }}
                                >
                                  {item.header}
                                </th>
                                <td
                                  style={{
                                    border: '1.5px solid black',
                                    paddingLeft: '30px',
                                    width: '70%',
                                    padding: '10px',
                                    fontWeight: '400',
                                    // borderRadius: '0 5px 5px 0',
                                  }}
                                >
                                  {item.value}
                                </td>
                              </tr>
                            ) : null
                          )}

                          {(listing?.other ? listing.other : []).map(
                            (item, index) => (
                              <tr key={index}>
                                <th
                                  style={{
                                    border: '1.5px solid black',
                                    textAlign: 'left',
                                    paddingLeft: '30px',
                                    width: '30%',
                                    padding: '8px',
                                    backgroundColor: 'white',
                                    fontWeight: '500',
                                    // borderRadius: '5px 0 0 5px',
                                  }}
                                >
                                  {item?.title}
                                </th>
                                <td
                                  style={{
                                    border: '1.5px solid black',
                                    paddingLeft: '30px',
                                    width: '70%',
                                    padding: '8px',
                                    fontWeight: '400',
                                    // borderRadius: '0 5px 5px 0',
                                  }}
                                >
                                  {item?.value}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid>
              )}
            </>
          ))
        )}
      </Grid>
      <div className="packageCardPageBottom">
        <div className="bottomLeft">
          <h1>Need something else or help in articles/ads?</h1>
          <h6>We are just one call/text/email away</h6>
        </div>
        <div className="bottomRight">
          <div className="bottomRightButtom">
            <a
              href="https://koinpr.com/call"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Schedule a call</span>
            </a>
          </div>
          <div className="bottomRightButtom telegram">
            <a
              href="https://koinpr.com/telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Telegram</span>
            </a>
          </div>
          <div className="bottomRightButtom email">
            <a
              href="mailto:support@koinpr.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Email</span>
            </a>
          </div>
          <div className="telegram-p">
            <a
              href="https://koinpr.com/telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
              Drop a text on telegram
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listing
