import React from 'react'
import './Listing.scss'
import { useParams } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useState, useContext } from 'react'
import AuthUserContext from '../../authentication/AuthUserContext'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../redux/actions'
import { removeFromCart } from '../../redux/actions'
import { getPublisherById } from '../../service/api'
import { addToCartAPI, deleteFromCartAPI } from '../../service/api.js'
import { useMediaQuery } from '@mui/material'
import '../Marketplace/marketplace.scss'
import { Constant } from '../../common'
import { activateCart } from '../../redux/actions'
import ListingCard from './listingCard'

function PublisherPage() {
  const authUser = useContext(AuthUserContext)
  const mobQuery = useMediaQuery("(max-width:'480px')")

  const styles = {
    root: {
      paddingLeft: 540,
      paddingRight: 70,
    },
    clickableText: {
      fontSize: mobQuery ? 16 : 14,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
    },
    pageText: {
      fontSize: mobQuery ? 14 : 12,
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: 14,
      marginRight: mobQuery ? 10 : 4,
      marginLeft: mobQuery ? 10 : 4,
    },
  }

  const [cardList, setCardList] = useState([])
  const [pressRelease, setPressRelease] = useState(true)
  const [sponsoredArticles, setSponsoredArticles] = useState(false)
  const [buttonAds, setButtonAds] = useState(false)
  const [bannerAds, setBannerAds] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [cartDataId, setCartDataIds] = useState([])
  const [selectedValue, setSelectedValue] = useState(Constant.PRESS_RELEASE)

  const [displayData, setDisplayData] = useState({ noData: true })
  const [originalCardList, setOriginalCardList] = useState({ noData: true })

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value)
    handleClick(event.target.value)
  }

  const handleDropdownChange = (selectedOption) => {
    console.log(selectedOption)
  }

  const handlePagination = (data) => {
    const paginatedData = []
    let tempData = []
    data.map((key, idx) => {
      tempData.push(data[idx])
      if (tempData.length == Constant.PAGE_SIZE) {
        paginatedData.push(tempData)
        tempData = []
      }
    })
    if (tempData.length) {
      paginatedData.push(tempData)
    }
    setCardList(paginatedData)
  }

  useEffect(() => {
    if (authUser) {
      const cartData = localStorage.getItem('cart')
        ? JSON.parse(localStorage.getItem('cart'))
        : null
      setCartDataIds(cartData ? cartData.map((el) => el?.id) : [])
      // console.log(cartData)
      dispatch(activateCart())
    }
  }, [authUser])

  const handleClick = (key) => {
    const currentPressReleaseStatus = pressRelease
    const currentSponsoredArticlesStatus = sponsoredArticles
    const currentButtonAdsStatus = buttonAds
    const currentBannerAdsStatus = bannerAds
    let data = originalCardList

    switch (key) {
      case Constant.PRESS_RELEASE:
        if (!currentPressReleaseStatus) {
          data = data.filter(
            (card) => card.listingCategory == Constant.PRESS_RELEASE
          )
        }
        setPressRelease(!currentPressReleaseStatus)
        setSponsoredArticles(false)
        setBannerAds(false)
        setButtonAds(false)
        break
      case Constant.SPONSORED_ARTICLES:
        if (!currentSponsoredArticlesStatus) {
          data = data.filter(
            (card) => card.listingCategory == Constant.SPONSORED_ARTICLES
          )
        }
        setPressRelease(false)
        setSponsoredArticles(!currentSponsoredArticlesStatus)
        setBannerAds(false)
        setButtonAds(false)
        break
      case Constant.BUTTON_ADS:
        if (!currentButtonAdsStatus) {
          data = data.filter(
            (card) => card.listingCategory == Constant.BUTTON_ADS
          )
        }
        setPressRelease(false)
        setSponsoredArticles(false)
        setBannerAds(false)
        setButtonAds(!currentButtonAdsStatus)
        break
      case Constant.BANNER_ADS:
        if (!currentBannerAdsStatus) {
          data = data.filter(
            (card) => card.listingCategory == Constant.BANNER_ADS
          )
        }
        setPressRelease(false)
        setSponsoredArticles(false)
        setBannerAds(!currentBannerAdsStatus)
        setButtonAds(false)
        break
    }
    handlePagination(data)
  }

  const filterByOfferTitle = (card, searchByName) => {
    const offerTitle = card.offerTitle

    if (searchByName.length > offerTitle.length) {
      return false
    }

    for (let i = 0; i < offerTitle.length; i++) {
      const subString = offerTitle.substr(i, searchByName.length)

      if (subString.toLowerCase() == searchByName.toLowerCase()) {
        return true
      }
    }

    return false
  }

  const getCategoryData = () => {
    if (pressRelease) {
      return originalCardList.filter(
        (card) => card.listingCategory == Constant.PRESS_RELEASE
      )
    } else if (sponsoredArticles) {
      return originalCardList.filter(
        (card) => card.listingCategory == Constant.SPONSORED_ARTICLES
      )
    } else if (buttonAds) {
      return originalCardList.filter(
        (card) => card.listingCategory == Constant.BUTTON_ADS
      )
    } else if (bannerAds) {
      return originalCardList.filter(
        (card) => card.listingCategory == Constant.BANNER_ADS
      )
    } else {
      return originalCardList
    }
  }

  const handleSearch = (event) => {
    let filterData
    if (!event.target.value) {
      filterData = getCategoryData()
    } else {
      filterData = originalCardList.filter((card) =>
        filterByOfferTitle(card, event.target.value)
      )
    }
    handlePagination(filterData)
  }

  const handlePageNumberChange = (key) => {
    const currentPageNumber = pageNumber
    if (key == Constant.INCREMENT_PAGENUMBER) {
      setPageNumber(currentPageNumber + 1)
    } else {
      setPageNumber(currentPageNumber - 1)
    }
  }

  const { id } = useParams()
  useEffect(() => {
    ;(async () => {
      if (!authUser) {
        return
      }
      const response = await getPublisherById(authUser._id)
      if (!response) {
        setDisplayData({ noData: true })
      } else {
        console.log(response)
        setDisplayData(response)
        const listing = response.listings
        if (!listing || listing.length === 0) {
          setOriginalCardList({ noData: true })
        } else {
          setOriginalCardList(listing)
          handlePagination(listing)
        }
      }
    })()
  }, [authUser])

  let tableData = [
    {
      header: 'Website',
      value: displayData?.websiteLink
        ? displayData?.websiteLink
        : 'Data Not Available',
      bool: displayData?.websiteLink ? true : false,
    },
    {
      header: 'Visitors',
      value: displayData?.visitors
        ? displayData?.visitors
        : 'Data Not Available',
      bool: displayData?.visitors ? true : false,
    },
    {
      header: 'Twitter',
      value: displayData?.twitter ? displayData?.twitter : 'Data Not Available',
      bool: displayData?.twitter ? true : false,
    },
    {
      header: 'Facebook',
      value: displayData?.facebook
        ? displayData?.facebook
        : 'Data Not Available',
      bool: displayData?.facebook ? true : false,
    },
    {
      header: 'LinkedIn',
      value: displayData?.linkedin
        ? displayData?.linkedin
        : 'Data Not Available',
      bool: displayData?.linkedin ? true : false,
    },
    {
      header: 'Regions',
      value:
        displayData?.regions == null
          ? ''
          : displayData?.regions.map((region) => region.name).join(', '),
      bool: displayData?.regions ? true : false,
    },
  ]

  const cartData = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null
  const cartDataIds = cartData ? cartData.map((el) => el?.id) : []

  const dispatch = useDispatch()
  const [isItemInCart, setIsItemInCart] = useState(false)

  const handleAddToCart = async () => {
    const { _id, details, image, price } = displayData

    if (cartDataIds.includes(_id)) {
      alert('Already added to the cart')
      return
    }

    const payload = {
      id: _id,
      name: displayData?.offerTitle,
      image,
      price,
      quantity: 1,
    }

    dispatch(addToCart(payload))
    setIsItemInCart(true)

    const body = {
      listingId: _id,
      name: displayData?.offerTitle,
      image,
      price,
      userId: authUser._id,
    }
    const data = await addToCartAPI(body)
    // console.log(data)
  }

  const handleRemoveFromCart = async (item) => {
    dispatch(removeFromCart(item))
    setIsItemInCart(false)
    // console.log(item)
    const data = await deleteFromCartAPI(authUser._id, item._id)
    // console.log(data)
  }

  useEffect(() => {
    setIsItemInCart(cartDataIds.includes(displayData?._id))
  }, [cartDataIds, displayData])

  const listingData = {
    displayData: displayData,
    tableData: tableData,
  }

  const publisherImage = displayData.image

  return (
    <div className="plContainer">
      {displayData?.noData ? (
        <h3>No Data Available</h3>
      ) : (
        <>
          <h3>Your Current Live Listings and Stats</h3>
          <Grid container xs={12}>
            <Grid item xs={12} md={6}>
              <div className="left">
                <div className="headExp">
                  <span className="image">
                    <Avatar
                      sx={{ width: '65px', height: '65px' }}
                      src={displayData?.image}
                    />
                  </span>
                  <span className="listTit">
                    {listingData.displayData?.companyName}
                  </span>
                </div>
                <span className="data">
                  <span className="listTitR" style={{ display: 'none' }}>
                    {listingData.displayData?.companyName}
                  </span>
                  {listingData.displayData?.description !== '' ? (
                    <>{listingData.displayData.description}</>
                  ) : (
                    <>Description Not available</>
                  )}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="right">
                <div
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <tbody>
                      {listingData.tableData.map((item, index) =>
                        item.bool ? (
                          <tr key={index}>
                            <th
                              style={{
                                border: '1.5px solid black',
                                textAlign: 'left',
                                paddingLeft: '30px',
                                width: '30%',
                                padding: '10px',
                                backgroundColor: 'white',
                                fontWeight: '500',
                              }}
                            >
                              {item.header}
                            </th>
                            <td
                              style={{
                                border: '1.5px solid black',
                                paddingLeft: '30px',
                                width: '70%',
                                padding: '10px',
                                fontWeight: '400',
                              }}
                            >
                              {item.value}
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="CartGrid">
            {cardList[pageNumber - 1] ? (
              cardList[pageNumber - 1]?.map((key, idx) => {
                return (
                  <div
                    className="tab"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ListingCard
                      cardData={cardList[pageNumber - 1][idx]}
                      cartDataIds={cartDataIds}
                      publisherImage={publisherImage}
                    />
                  </div>
                )
              })
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              marginLeft: '0rem',
            }}
          >
            <h3
              style={{ width: '97%', marginBottom: '0rem', marginTop: '3rem' }}
            >
              For changes or addition of services please contact us
            </h3>
          </div>
        </>
      )}
    </div>
  )
}

export default PublisherPage
