import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { Badge, Menu, MenuItem, Slide, Tooltip } from '@mui/material'
import Cookies from 'universal-cookie'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

import { Link, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
import { useState, useContext, useEffect } from 'react'
import AuthUserContext from '../../authentication/AuthUserContext'

// import { snackbarNotification } from "../../../redux/snackbar.action";

import './header.scss'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function MobileHeader({
  handleTelegram,
  badgeContent,
  contactUsHandler,
}) {
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const cookies = new Cookies()
  const [userData, setUserData] = useState()
  const dispatch = useDispatch()
  const location = window.location.href
  const [userId, setUserId] = useState()
  const navigate = useNavigate()

  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleUserTypeOrderHistory = (userType) => {
    setAnchorElUser(null)
    if (userType === 'PUBLISHER') {
      navigate('/wallet-history')
    } else if (userType === 'ADVERTISER') {
      //navigate to order history
      navigate('/order-history')
    }
  }

  const signOutHandler = () => {
    cookies.remove('auth-token')
    handleSignout()
    const data = {
      notificationType: 'success',
      notificationMessage: 'Logged Out Successfully',
    }
    window.location.reload()
  }
  const handleSignout = (event) => {}

  const authuser = useContext(AuthUserContext)

  useEffect(() => {
    if (authuser != null) {
      // console.log(authuser)
      setUserId(authuser._id)
      setUserData(authuser)
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [authuser])

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))

  const handleRedirect = () => {
    if (isLoggedIn) {
      navigate('/')
    } else {
      navigate('/')
    }
  }

  return (
    <Box sx={{ marginBottom: '6em' }}>
      <AppBar sx={{ background: 'black' }} position="fixed">
        <Toolbar
          sx={{ padding: '0.6em 1.2em', justifyContent: 'space-between' }}
          variant="dense"
        >
          <Typography sx={{ fontSize: '1rem', paddingLeft: '2%' }}>
            <div
              className="Koin_head"
              style={{ fontWeight: 600, fontSize: 25, lineHeight: '33px' }}
              onClick={handleRedirect}
            >
              Koinpr
            </div>
            <p
              className="Koin_sub"
              style={{ fontWeight: 300, fontSize: 12, marginLeft: '22.5px' }}
              onClick={handleRedirect}
            >
              A <span style={{ fontWeight: 'bold' }}>Todayq</span> Product
            </p>
          </Typography>

          <div
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1.5rem',
              alignItems: 'center',
            }}
          >
            {isLoggedIn && userData?.userType === 'ADVERTISER' ? (
              <IconButton
                sx={{ padding: '0 !important' }}
                onClick={() => navigate('/cart-checkout')}
                aria-label="cart"
              >
                <StyledBadge badgeContent={badgeContent} color="primary">
                  <ShoppingCartIcon
                    sx={{ color: 'white', marginLeft: '-16px' }}
                  />
                </StyledBadge>
              </IconButton>
            ) : null}

            {isLoggedIn ? (
              <Box>
                <Tooltip title="Open Accout">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Typography
                      sx={{ color: 'white', fontWeight: 500, fontSize: '14px' }}
                    >
                      My Account
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography
                      onClick={() => navigate('/profile')}
                      textAlign="center"
                    >
                      My Profile
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography
                      onClick={() => navigate('/packages')}
                      textAlign="center"
                    >
                      Packages
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleUserTypeOrderHistory(userData?.userType)
                    }
                  >
                    <Typography textAlign="center">
                      {userData?.userType === 'ADVERTISER'
                        ? 'Order History'
                        : userData?.userType === 'PUBLISHER'
                        ? 'Wallet History'
                        : null}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">
                      <Link to="/add-telegram">Telegram</Link>
                    </Typography>
                  </MenuItem>
                  {/* {userData?.userType === 'PUBLISHER' ? (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">
                        <Link to="/add-listing">Add Listing</Link>
                      </Typography>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )} */}

                  {isLoggedIn && userData?.userType === 'ADVERTISER' ? (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">
                        <Link to="/cart-checkout">Cart</Link>
                      </Typography>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )}

                  <MenuItem
                    sx={{
                      textAlign: 'center',
                    }}
                    onClick={contactUsHandler}
                  >
                    <Typography>Contact Us</Typography>
                  </MenuItem>

                  <MenuItem onClick={signOutHandler}>
                    <Typography textAlign="center">
                      {isLoggedIn ? (
                        <span onClick={handleSignout}>Sign Out</span>
                      ) : (
                        <span style={{ border: '1px solid red' }}>
                          location.split('/')[location.split('/').length-1] ===
                          "sign-up" ? "Sign In" : "Sign Up"
                        </span>
                      )}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <span>
                {/* Login */}
                <Link
                  // style={{ marginLeft: '60px' }}
                  to={
                    location.split('/')[location.split('/').length - 1] ===
                    'sign-up'
                      ? '/sign-in'
                      : '/sign-up'
                  }
                >
                  {isLoggedIn ? (
                    <span onClick={handleSignout}>Sign Out</span>
                  ) : location.split('/')[location.split('/').length - 1] ===
                    'sign-up' ? (
                    <span>
                      <Link className="sign" to="/sign-in">
                        Sign in
                      </Link>
                    </span>
                  ) : (
                    <span>
                      <Link className="sign" to="/sign-up">
                        Sign Up
                      </Link>
                    </span>
                  )}
                </Link>
              </span>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div></div>
    </Box>
  )
}
