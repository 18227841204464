import React, { useEffect, useState, useContext } from 'react'
import MarketplaceCard from './marketplaceCard'
import {
  getBannerDataApi,
  getPublisher,
  getRegionsDataApi,
} from '../../service/api'
import './marketplace.scss'
import { Constant } from '../../common'
import Cookies from 'universal-cookie'
import { activateCart } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search'
import { Typography } from '@material-ui/core'
import AuthUserContext from '../../authentication/AuthUserContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../dropdown/dropdown'
import ScheduleCall from '../marketCall/marketCall'
import Telegram from '../../assets/telegram.png'
import Support from '../../assets/support.png'
import MultiSelectDropdown from '../dropdown/MultiSelectDropdown.js'
import { useMediaQuery } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import Loader from '../Loader/Loader'

function Marketplace() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 540)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 540)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const mobQuery = useMediaQuery("(max-width:'480px')")

  const styles = {
    root: {
      paddingLeft: 540,
      paddingRight: 70,
    },
    clickableText: {
      fontSize: mobQuery ? 16 : 14,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
    },
    pageText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: mobQuery ? 'flex-start' : 'center',
      fontSize: mobQuery ? 12 : 14,
      fontWeight: mobQuery ? 500 : 700,
      fontFamily: 'Inter, sans-serif',
    },
    icon: {
      fontSize: 14,
      marginRight: mobQuery ? 10 : 4,
      marginLeft: mobQuery ? 10 : 4,
    },
  }

  const [cardList, setCardList] = useState([])
  const [temp, setTemp] = useState(false)
  const [loading, setLoading] = useState(true)
  const [originalCardList, setOriginalCardList] = useState([])
  const [bannerData, setBannerData] = useState()
  const authUser = useContext(AuthUserContext)
  const [contentOffering, setContentOffering] = useState(true)
  const [adsOffering, setAdsOffering] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [cartDataIds, setCartDataIds] = useState([])
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [regions, setRegions] = useState([])
  const [selectedregions, setSelectedRegions] = useState([])
  const [linkType, setLinkType] = useState('')
  const [socialShare, setSocialShare] = useState('')
  const [priceRange, setPriceRange] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [allRegions, setAllRegions] = useState([])
  const [adType, setAdType] = useState('')
  const [pageSize, setPageSize] = useState(9)
  const [isLinkTypeOpen, setLinkTypeOpen] = useState(false)
  const [isSocialShareOpen, setSocialShareOpen] = useState(false)
  const [isRegionOpen, setRegionOpen] = useState(false)
  const [isAdOpen, setAdOpen] = useState(false)
  const [isPriceRangeOpen, setPriceRangeOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [offeringType, setOfferingType] = useState('')

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

  const handleRegionChange = (_, value) => {
    setSelectedRegions(value)
    console.log(value)
  }

  const getRegionsData = () => {
    getRegionsDataApi().then((res) => {
      setRegions(res.data?.data)
      setAllRegions(res.data?.data)
    })
  }

  const getBannerData = () => {
    getBannerDataApi().then((res) => {
      setBannerData(res.data?.data)
      console.log('Banner.......', res.data?.data)
    })
  }
  const [resetDropdown, setResetDropdown] = useState(false)

  const handleResetFilters = () => {
    setLinkType('')
    setSocialShare('')
    setPriceRange('')
    setAdType('')
    setSelectedRegions([])
    setDisplayValue('')
    setCardList(originalCardList)
    setResetDropdown(true)
  }

  const handleDropdownChange = (selectedOption) => {
    console.log(selectedOption)
  }

  const handleFilterChange = async () => {
    let data = originalCardList
      .filter((item) =>
        item.listings.some(
          (list) => list.offeringCategory === Constant.CONTENT_OFFERING
        )
      )
      .map((item) => ({
        ...item,
        listings: item.listings.filter(
          (list) => list.offeringCategory === Constant.CONTENT_OFFERING
        ),
      }))

    console.log('content', data)
    console.log(socialShare)

    if (socialShare != '') {
      data = data
        .filter((item) =>
          item.listings.some((list) => list.socialShare === socialShare)
        )
        .map((item) => ({
          ...item,
          listings: item.listings.filter(
            (list) => list.socialShare === socialShare
          ),
        }))
    }

    if (linkType != '') {
      let field = ''
      if (linkType == 'Do follow') {
        field = 'doFollowLink'
      }
      if (linkType == 'No follow') {
        field = 'noFollowLink'
      }
      if (linkType == 'No Link') {
        field = 'noLink'
      }
      console.log(field)
      data = data
        .filter((item) => item.listings.some((list) => list.linkType === field))
        .map((item) => ({
          ...item,
          listings: item.listings.filter((list) => list.linkType === field),
        }))
    }

    if (selectedregions != null && selectedregions.length > 0) {
      console.log(selectedregions)
      data = data.filter((element) =>
        element.regions.some((region) =>
          selectedregions.some(
            (selectedRegion) => region._id === selectedRegion._id
          )
        )
      )
    }
    data = data.filter((card) => filterByOfferTitle(card, searchInput))
    setCardList(data)
    setTemp(!temp)
    console.log(cardList)
    console.log('link', linkType)
    console.log('socialShare', socialShare)

    // handlePriceRangeChange(priceRange)
  }

  useEffect(() => {
    if (adsOffering) {
      return
    }
    handleFilterChange()
  }, [
    socialShare,
    linkType,
    selectedregions,
    contentOffering,
    adsOffering,
    searchInput,
  ])

  useEffect(() => {
    if (!adsOffering) {
      return
    }
    handleAdTypeChange()
  }, [adType, selectedregions, contentOffering, adsOffering, searchInput])

  useEffect(() => {
    handlePriceRangeChange(priceRange)
  }, [temp])

  const handleAdTypeChange = () => {
    console.log('right place')
    let data = originalCardList
      .filter((item) =>
        item.listings.some(
          (list) => list.offeringCategory === Constant.ADS_OFFERING
        )
      )
      .map((item) => ({
        ...item,
        listings: item.listings.filter(
          (list) => list.offeringCategory === Constant.ADS_OFFERING
        ),
      }))
    console.log('ads:', data)
    if (adType != '') {
      data = data
        .filter((item) =>
          item.listings.some((list) => list.listingCategory === adType)
        )
        .map((item) => ({
          ...item,
          listings: item.listings.filter(
            (list) => list.listingCategory === adType
          ),
        }))
    }

    console.log(data)
    if (selectedregions != null && selectedregions.length > 0) {
      console.log(selectedregions)
      data = data.filter((element) =>
        element.regions.some((region) =>
          selectedregions.some(
            (selectedRegion) => region._id === selectedRegion._id
          )
        )
      )
    }
    data = data.filter((card) => filterByOfferTitle(card, searchInput))
    console.log(data)
    setCardList(data)
  }

  const handlePriceRangeChange = (selectedOption) => {
    setPriceRange(selectedOption)
    console.log('updating price....')
    console.log(selectedOption)
    let sortedList
    if (selectedOption === 'Low to High') {
      sortedList = [...cardList].sort((a, b) => {
        const priorityA = Math.min(
          ...a.listings.map((item) =>
            Math.max(item.listingPrice, item.minPrice, item.fixedPrice)
          )
        )
        const priorityB = Math.min(
          ...b.listings.map((item) =>
            Math.max(item.listingPrice, item.minPrice, item.fixedPrice)
          )
        )
        return priorityA - priorityB
      })
    } else if (selectedOption === 'High to Low') {
      sortedList = [...cardList].sort((a, b) => {
        const priorityA = Math.min(
          ...a.listings.map((item) =>
            Math.max(item.listingPrice, item.minPrice, item.fixedPrice)
          )
        )
        const priorityB = Math.min(
          ...b.listings.map((item) =>
            Math.max(item.listingPrice, item.minPrice, item.fixedPrice)
          )
        )
        return priorityB - priorityA
      })
    } else {
      sortedList = cardList
    }
    console.log(sortedList)
    setCardList(sortedList)
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      let response = await getPublisher()
      console.log('check the data')
      console.log(response)
      setOriginalCardList(response)

      let data = response
        .filter((item) =>
          item.listings.some(
            (list) => list.offeringCategory === Constant.CONTENT_OFFERING
          )
        )
        .map((item) => ({
          ...item,
          listings: item.listings.filter(
            (list) => list.offeringCategory === Constant.CONTENT_OFFERING
          ),
        }))

      console.log(data)
      setCardList(data)
      getRegionsData()
      getBannerData()
      setLoading(false)
    })()
  }, [authUser])

  useEffect(() => {
    if (authUser) {
      const cartData = localStorage.getItem('cart')
        ? JSON.parse(localStorage.getItem('cart'))
        : null
      setCartDataIds(cartData ? cartData.map((el) => el?.id) : [])
      dispatch(activateCart())
    }
  }, [authUser])

  // useEffect(() => {
  //   const filteredList = originalCardList.filter((card) =>
  //     selectedregions.some((region) => card.region === region.name)
  //   )
  //   setCardList(filteredList)
  // }, [selectedregions])
  const [displayValue, setDisplayValue] = useState('')

  const handleClick = (key) => {
    console.log('aya ethe')
    const currentcontentOfferingStatus = contentOffering
    const currentadsOfferingStatus = adsOffering
    let data = originalCardList

    switch (key) {
      case Constant.CONTENT_OFFERING:
        setAdType('')
        setSelectedRegions([])
        setContentOffering(true)
        setAdsOffering(false)
        setOfferingType('Content Distribution')
        const offeringTypeDisplayValue = isMobile
          ? 'Content Dis....'
          : 'Content Distribution'
        setDisplayValue(offeringTypeDisplayValue)

        break
      case Constant.ADS_OFFERING:
        setLinkType('')
        setSocialShare('')
        setSelectedRegions([])
        setContentOffering(false)
        setAdsOffering(true)
        setOfferingType('Advertisement')
        setDisplayValue('Advertisement')
        break
      case Constant.CUSTOM_PACKAGE:
        navigate('/packages')
        break
      default:
        data = originalCardList
        setContentOffering(false)
        setAdsOffering(false)
    }
    // console.log(data)
    // setCardList(data)
  }

  const filterByOfferTitle = (card, searchByName) => {
    console.log(card)
    const offerTitle = card?.companyName

    if (searchByName.length > offerTitle.length) {
      return false
    }

    for (let i = 0; i < offerTitle.length; i++) {
      const subString = offerTitle.substr(i, searchByName.length)

      if (subString.toLowerCase() == searchByName.toLowerCase()) {
        return true
      }
    }

    return false
  }

  const getCategoryData = () => {
    if (contentOffering) {
      return originalCardList.filter((a) =>
        a.listings.some(
          (listing) => listing.offeringCategory == Constant.CONTENT_OFFERING
        )
      )
    } else if (adsOffering) {
      return originalCardList.filter((a) =>
        a.listings.some(
          (listing) => listing.offeringCategory == Constant.ADS_OFFERING
        )
      )
    } else {
      return originalCardList
    }
  }

  const handleSearch = (event) => {
    setSearchInput(event.target.value)
  }

  const handlePageNumberChange = () => {
    setLoading(true)

    setTimeout(() => {
      setPageSize(pageSize + 9)
      handlePriceRangeChange(priceRange)
      setLoading(false)
    }, 100)
  }

  const handleSelectionChange = (selectedOptions) => {
    console.log('here', selectedOptions)
    setSelectedRegions(selectedOptions)
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [placeholderText, setPlaceholderText] = useState('Enter publisher name')

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  useEffect(() => {
    if (windowWidth < 540) {
      setPlaceholderText('Publisher')
    } else {
      setPlaceholderText('Enter publisher name')
    }
  }, [windowWidth])

  return (
    <div className="Market">
      {loading && <Loader />}
      <div className="Market__Left">
        <div className="Market__Left-head">
          <p>Search publishers</p>
        </div>
        <div className="Market__Left-input">
          <input
            placeholder={placeholderText}
            type="input"
            name="account"
            value={searchInput}
            onChange={(event) => handleSearch(event)}
          />
          <SearchIcon
            className="SearchIcon"
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '10px',
            }}
          />
        </div>
        <div className="Market__Left-head">
          <p>Category</p>
        </div>
        <div className="Market__Left-form">
          <form>
            <div
              className="Market__Left-form--input"
              onClick={() => handleClick(Constant.CONTENT_OFFERING)}
            >
              <label className="label">Content Distribution</label>
              {contentOffering ? (
                <CheckCircleIcon />
              ) : (
                <CheckCircleOutlineIcon />
              )}
            </div>
          </form>
          <form>
            <div
              className="Market__Left-form--input"
              onClick={() => handleClick(Constant.ADS_OFFERING)}
            >
              <label className="label">Advertisement</label>
              {adsOffering ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
            </div>
          </form>
          <button
            className="Market__Left-form--button"
            onClick={() => handleClick(Constant.CUSTOM_PACKAGE)}
          >
            <span className="label">Custom Package</span>
          </button>
        </div>

        <div className="Market__Left-mobileDropdown">
          <Dropdown
            options={[
              'Content Distribution',
              'Advertisement',
              'Custom Package',
            ]}
            reset={resetDropdown}
            onChange={(a) => {
              if (a === 'Content Distribution') {
                handleClick(Constant.CONTENT_OFFERING)
              }
              if (a === 'Advertisement') {
                handleClick(Constant.ADS_OFFERING)
              }
              if (a === 'Custom Package') {
                navigate('/packages')
              }
            }}
            placeholder="Category"
            onOpenClose={toggleDropdown}
            isOpen={dropdownOpen}
            value={displayValue} // Use displayValue here
          />
        </div>
        <div className="marketScheduleCall">
          <ScheduleCall />
        </div>
      </div>
      <div className="Market__Right">
        {!isMobile &&
          bannerData != undefined &&
          bannerData.length > 0 &&
          bannerData[0]?.active === true && (
            <a
              href={bannerData[0]?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="banner">
                <img src={bannerData[0]?.image} className="banner-image" />
              </div>
            </a>
          )}
        <div
          className={`Market__Right--filter ${
            bannerData != undefined &&
            bannerData.length > 0 &&
            bannerData[0]?.active === true &&
            'banner-active'
          }`}
        >
          {contentOffering && (
            <React.Fragment>
              <div className="Market__Right--filter-box">
                <Dropdown
                  options={['Do follow', 'No follow', 'No Link']}
                  onChange={(a) => {
                    setSocialShare('')
                    setLinkType(a)
                  }}
                  reset={resetDropdown}
                  value={linkType}
                  placeholder="Link Type"
                  onOpenClose={() => {
                    setSocialShareOpen(false)
                    setRegionOpen(false)
                    setLinkTypeOpen(!isLinkTypeOpen)
                    setPriceRangeOpen(false)
                  }}
                  isOpen={isLinkTypeOpen}
                />
              </div>
              <div className="Market__Right--filter-box">
                <Dropdown
                  options={['Yes']}
                  onChange={(a) => {
                    setLinkType('')
                    setSocialShare(a)
                  }}
                  reset={resetDropdown}
                  value={socialShare}
                  placeholder="Social Share"
                  onOpenClose={() => {
                    setSocialShareOpen(!isSocialShareOpen)
                    setRegionOpen(false)
                    setLinkTypeOpen(false)
                    setPriceRangeOpen(false)
                  }}
                  isOpen={isSocialShareOpen}
                />
              </div>
              <div className="Market__Right--filter-box">
                <Dropdown
                  options={['Low to High', 'High to Low']}
                  onChange={handlePriceRangeChange}
                  value={priceRange}
                  placeholder="Price Range"
                  reset={resetDropdown}
                  onOpenClose={() => {
                    setSocialShareOpen(false)
                    setRegionOpen(false)
                    setLinkTypeOpen(false)
                    setPriceRangeOpen(!isPriceRangeOpen)
                  }}
                  isOpen={isPriceRangeOpen}
                />
              </div>
              <div className="Market__Right--filter-box">
                <MultiSelectDropdown
                  options={regions}
                  onChange={handleSelectionChange}
                  placeholder="Regions"
                  onOpenClose={() => {
                    setSocialShareOpen(false)
                    setRegionOpen(!isRegionOpen)
                    setLinkTypeOpen(false)
                    setPriceRangeOpen(false)
                  }}
                  isOpen={isRegionOpen}
                  selectedRegions={selectedregions}
                />
              </div>
            </React.Fragment>
          )}

          {adsOffering && (
            <React.Fragment>
              <div className="Market__Right--filter-box">
                <Dropdown
                  options={[
                    'Homepage header banner ad',
                    'Homepage footer ad',
                    'Post page sidebar ad',
                    'Post page text ad (in article)',
                    'Constant header button ad',
                  ]}
                  reset={resetDropdown}
                  onChange={setAdType}
                  value={adType}
                  placeholder="Ad Type"
                  onOpenClose={() => {
                    setAdOpen(!isAdOpen)
                    setRegionOpen(false)
                  }}
                  isOpen={isAdOpen}
                />
              </div>
              <div className="Market__Right--filter-box">
                <MultiSelectDropdown
                  options={regions}
                  onChange={handleSelectionChange}
                  placeholder="Regions"
                  onOpenClose={() => {
                    setAdOpen(false)
                    setRegionOpen(!isRegionOpen)
                  }}
                  isOpen={isRegionOpen}
                  selectedRegions={selectedregions}
                />
              </div>
            </React.Fragment>
          )}
          <div
            onClick={handleResetFilters}
            className="Market__Right--filter-reset"
            style={{ cursor: 'pointer' }}
          >
            Reset filter
            <RefreshIcon
              sx={{
                color: ' #4E4D4D',
              }}
            ></RefreshIcon>
          </div>
        </div>
        {isMobile &&
          bannerData != undefined &&
          bannerData.length > 0 &&
          bannerData[0]?.active === true && (
            <a href={bannerData[0]?.link}>
              <div className="banner">
                <img src={bannerData[0]?.image} className="banner-image" />
              </div>
            </a>
          )}
        <div className="Market__Right-cardSpace">
          {cardList
            .slice(0, Math.min(pageSize, cardList.length))
            .map((key, idx) => {
              return (
                <div className="tab">
                  <MarketplaceCard
                    cardData={cardList[idx]}
                    cartDataIds={cartDataIds}
                    linkType={linkType}
                    socialShare={socialShare}
                    adType={adType}
                    filterType={
                      contentOffering ? 'contentOffering' : 'adsOffering'
                    }
                  />
                  {((idx >= 2 && (idx - 2) % 7 === 0) || idx === 2) && (
                    <div className="mobile-schedule-call">
                      <ScheduleCall />
                    </div>
                  )}
                </div>
              )
            })}
        </div>
        {cardList.length > pageSize && (
          <div container className="Market__Right-pagination">
            <button onClick={() => handlePageNumberChange()}>
              <Typography variant="body1" style={styles.pageText}>
                Show More
              </Typography>
            </button>
          </div>
        )}
      </div>

      <div className="floatIcon">
        <a href="https://www.koinpr.com/contact" className="icon">
          <img className="ic" src={Support} />
        </a>
        <a href="https://www.koinpr.com/telegram" className="icon">
          <img className="ic" src={Telegram} />
        </a>
      </div>
    </div>
  )
}

export default Marketplace
