import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Dropdown.scss'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { useEffect } from 'react'

const Dropdown = ({
  options,
  onChange,
  placeholder,
  label,
  value,
  onOpenClose,
  isOpen,
  reset,
}) => {
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (reset) {
      setSelected(false)
    }
  }, [reset])

  const onOptionClicked = (value) => () => {
    setSelected(true)
    onOpenClose()
    onChange(value)
  }

  const borderStyle =
    value && (selected || isOpen)
      ? '2px solid rgb(108 156 255)'
      : '2px solid black'

  const colorStyle = value && (selected || isOpen) ? 'rgb(83,81,73)' : 'black'

  return (
    <div className="Dropdown">
      <label>{label}</label>
      <div
        className="Dropdown__header"
        onClick={onOpenClose}
        style={{
          border: borderStyle,
          color: colorStyle,
        }}
      >
        {value === '' ? placeholder : value || placeholder}
        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {isOpen && (
        <div className="Dropdown__list-container">
          <div className="Dropdown__list">
            {options.map((option) => (
              <div
                onClick={onOptionClicked(option)}
                className="Dropdown__list-option"
                key={Math.random()}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpenClose: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
}

Dropdown.defaultProps = {
  placeholder: 'Select...',
}

export default Dropdown
