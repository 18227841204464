import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import stripe from '../../assets/stripe.png'
import { removeFromCart } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { useContext } from 'react'
import AuthUserContext from '../../authentication/AuthUserContext'
import Cookies from 'universal-cookie'
import './Cart.scss'
import PayButton from './stripe/PayButton'
import { Constant } from '../../common'
import { deleteFromCartAPI } from '../../service/api.js'
import { Avatar } from '@mui/material'

function CartCheckout() {
  const cookies = new Cookies()
  const authUser = useContext(AuthUserContext)

  const dispatch = useDispatch()
  const cartDatafromState = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : []

  let finalprice = 0
  for (const { price } of cartDatafromState) {
    finalprice += price
  }
  const originalFinalPrice = finalprice
  const [cartData, setCartData] = useState({
    cartDataArray: cartDatafromState,
    finalprice: finalprice,
  })

  console.log(cartData)

  const [payViaStripe, setPayViaStripe] = useState(false)
  const [payViaCoinGate, setPayViaCoinGate] = useState(true)
  const [stripeMultiplierApplied, setStripeMultiplierApplied] = useState(false)

  const handleClick = (key) => {
    switch (key) {
      case Constant.PAY_VIA_STRIPE:
        setPayViaStripe(true)
        setPayViaCoinGate(false)
        if (!stripeMultiplierApplied) {
          setStripeMultiplierApplied(true)
          setCartData((prevCartData) => ({
            ...prevCartData,
            // finalprice: Math.round(prevCartData.finalprice * 1.03),
            finalprice: (prevCartData.finalprice * 1.03).toFixed(2),
          }))
        }
        break
      case Constant.PAY_VIA_COINGATE:
        setStripeMultiplierApplied(false)
        setPayViaStripe(false)
        setPayViaCoinGate(true)
        setCartData((prevCartData) => ({
          ...prevCartData,
          finalprice: originalFinalPrice,
        }))
        break
    }
  }

  const handleRemoveFromCart = async (item) => {
    console.log(item)
    dispatch(removeFromCart(item))
    if (item.type == 'package') {
      const data = await deleteFromCartAPI({
        userId: item.userId,
        packageId: item.packageId,
        type: 'package',
      })
      console.log(data)
    } else {
      const data = await deleteFromCartAPI({
        userId: item.userId,
        listingId: item.listingId,
        type: 'listing',
      })
      console.log(data)
    }
    let tempCart = cartData.cartDataArray
    const indexToRemove = tempCart.findIndex((el) => el.id === item.id)
    finalprice -= tempCart[indexToRemove].price
    if (indexToRemove !== -1) {
      tempCart.splice(indexToRemove, 1)
    }

    const updatedCartData = {
      cartDataArray: tempCart,
      finalprice: finalprice,
    }
    setCartData(updatedCartData)
  }

  return (
    <div className="Cart">
      <div className="hidden md:block  font-bold text-2xl">
        <h2 className="cart_md">Cart</h2>
      </div>
      {cartData.cartDataArray.length > 0 ? (
        <div className="content">
          <div className="hidden md:block cartFront">
            {cartData.cartDataArray?.map((el) => (
              <div key={el?.id} className="left">
                <div className="item">
                  <div className="itemLeft">
                    {' '}
                    <Avatar class="image" src={el.image} />
                    <span className="title">{el.name}</span>
                  </div>
                  <div className="itemRight">
                    <span className="price">${el.price}</span>
                    <span className="cross">
                      <button
                        type="button"
                        onClick={() => handleRemoveFromCart(el)}
                      >
                        {<CancelIcon />}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="right">
            <div className="title">Checkout</div>
            <div className="amount space-x-3">
              <span>Total : ${cartData?.finalprice}</span>
              {payViaStripe && (
                <span className="text-sm text-[#939393] italic mt-2">
                  Additional Charges for payment gateway (3%)
                </span>
              )}
            </div>
            <div className="inputs">
              <button onClick={() => handleClick(Constant.PAY_VIA_COINGATE)}>
                <div className="input">
                  <div className="input_flat">
                    <label style={{ cursor: 'pointer' }}>Pay via</label>

                    <span
                      style={{
                        paddingLeft: '5px',
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      Crypto
                    </span>
                  </div>

                  <input
                    type="radio"
                    name="paymentMethod"
                    className="ip"
                    value="Crypto"
                    onChange={() => handleClick(Constant.PAY_VIA_COINGATE)}
                    checked={payViaCoinGate}
                  ></input>
                </div>
              </button>
              <button onClick={() => handleClick(Constant.PAY_VIA_STRIPE)}>
                <div className="input">
                  <div className="input_flat">
                    <label style={{ cursor: 'pointer' }}>Pay via</label>

                    <span>
                      <img src={stripe} alt="stripe" />
                    </span>
                  </div>
                  <input
                    type="radio"
                    value="stripe"
                    name="paymentMethod"
                    className="ip"
                    onChange={() => handleClick(Constant.PAY_VIA_STRIPE)}
                    checked={payViaStripe}
                  ></input>
                </div>
              </button>
            </div>
            <button type="button" className="placeOrd">
              <PayButton
                cartItem={cartData.cartDataArray}
                paymentMethod={
                  payViaStripe
                    ? Constant.PAY_VIA_STRIPE
                    : Constant.PAY_VIA_COINGATE
                }
              />
            </button>
          </div>
          <div className="md:hidden sm:block">
            <div className="md:hidden sm:block font-bold text-2xl">
              <h2 className="cart">Cart</h2>
            </div>
            {cartData.cartDataArray?.map((el) => (
              <div className="left">
                <div className="item">
                  <div className="itemLeft">
                    {' '}
                    <Avatar class="image" src={el.image} />
                    <span className="title tit_head">{el.name}</span>
                  </div>
                  <div className="itemRight">
                    <span className="price">${el.price}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveFromCart(el)}
                      className="cross"
                      style={{ float: 'right' }}
                    >
                      {<CancelIcon />}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        'Your cart is empty'
      )}
    </div>
  )
}

export default CartCheckout
