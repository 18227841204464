import React from 'react'
import '../Footer/footer.scss'

function footer() {
  return (
    <div className="footerContainer">
      <div className="footerLeft">
        <h3>Koinpr Marketing Private Limited</h3>
        <p>CIN: JSB29SN20N02NS0N29N2</p>
      </div>
      <div className="footerRight">
        <a
          href="https://koinpr.com/telegram"
          target="_blank"
          rel="noopener noreferrer"
        >
          @teamkoinpr
        </a>
        <a
          href="mailto:support@koinpr.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@koinpr.com
        </a>
        <a
          href="https://koinpr.com/call"
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a call
        </a>
      </div>
    </div>
  )
}

export default footer
