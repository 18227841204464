import { Avatar } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AuthUserContext from '../../authentication/AuthUserContext'
import { addToCart } from '../../redux/actions'
import { useContext } from 'react'
import { removeFromCart } from '../../redux/actions'
import { Link, useNavigate } from 'react-router-dom'
import { addToCartAPI, deleteFromCartAPI } from '../../service/api.js'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { BsCartPlus, BsFillCartCheckFill } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'

function MarketplaceCard(props) {
  const authUser = useContext(AuthUserContext)
  const cardData = props.cardData
  const socialShare = props.socialShare
  const linkType = props.linkType
  const adType = props.adType
  const navigate = useNavigate()
  const location = useLocation()
  const filterType = props.filterType
  const [startingPrice, setStartingPrice] = useState(0)
  const [loading, setLoading] = useState(false)
  // console.log(linkType,socialShare)
  const cartData = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null
  const cartDataIds = cartData ? cartData.map((el) => el?.listingId) : []
  const dispatch = useDispatch()
  const [isItemInCart, setIsItemInCart] = useState(false)

  useEffect(() => {
    console.log(cartDataIds)
    setIsItemInCart(false)
    if (cartDataIds.includes(cardData.listings[0]._id)) {
      setIsItemInCart(true)
    }
    if (cardData == undefined || cardData.listings == undefined) {
      return
    }
    let temp = Number.MAX_SAFE_INTEGER
    for (let i = 0; i < cardData.listings.length; i++) {
      temp = Math.min(
        temp,
        Math.max(
          cardData.listings[i].listingPrice,
          cardData.listings[i].minPrice,
          cardData.listings[i].fixedPrice
        )
      )
      console.log(
        cardData.listings[i].listingPrice,
        cardData.listings[i].minPrice,
        cardData.listings[i].fixedPrice
      )
    }
    setStartingPrice(temp)
  }, [authUser, cardData])

  const handleAddToCart = async () => {
    if (!authUser) {
      navigate('/sign-in', { state: { from: location } })
      return
    }
    console.log(cardData)
    const { _id, listingPrice, offerTitle } = cardData.listings[0]
    const { image } = cardData

    if (cartDataIds.includes(_id)) {
      alert('Already added to the cart')
      return
    }

    const payload = {
      listingId: _id,
      type: 'listing',
      name: offerTitle,
      userId: authUser._id,
      image,
      price: listingPrice,
    }
    console.log(payload)

    dispatch(addToCart(payload))
    setIsItemInCart(true)

    const body = {
      listingId: _id,
      type: 'listing',
      name: offerTitle,
      image: image,
      userId: authUser._id,
      price: listingPrice,
    }

    const data = await addToCartAPI(body)
    console.log('item added........', data)
  }

  const handleRemoveFromCart = async () => {
    const { _id, listingPrice, offerTitle } = cardData.listings[0]
    const { image } = cardData

    const payload = {
      listingId: _id,
      type: 'listing',
      name: offerTitle,
      userId: authUser._id,
      image,
      price: listingPrice,
    }

    dispatch(removeFromCart(payload))
    setIsItemInCart(false)
    const data = await deleteFromCartAPI({
      userId: authUser._id,
      type: 'listing',
      listingId: _id,
    })
    console.log('removed...', data)
  }

  return (
    <div
      className="card"
      style={{
        width: '100%',
        background: 'white',
        padding: '22px 22px 19px 22px',
        // marginBottom: '30px',
        borderRadius: '5px',
      }}
    >
      <span>
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginTop: '12px',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '1rem',
          }}
        >
          <Link
            to={
              linkType == '' && socialShare == '' && adType == ''
                ? `/listing/${cardData.user._id}`
                : `/listing/${cardData.user._id}?elementId=${cardData.listings[0]._id}`
            }
          >
            <div
              sx={{
                // width: '20% !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{ width: '54px', height: '54px' }}
                src={cardData.image}
              />
            </div>
          </Link>

          <div
            className="cardTittle"
            style={{
              // width: '66%',
              fontSize: '14px',
              fontWeight: 600,
            }}
          >
            <Link
              to={
                linkType == '' && socialShare == '' && adType == ''
                  ? `/listing/${cardData.user._id}`
                  : `/listing/${cardData.user._id}?elementId=${cardData.listings[0]._id}`
              }
            >
              <p>{cardData.companyName}</p>
            </Link>
          </div>
        </div>

        <div
          style={{
            marginTop: '34px',
            color: '#4E4D4D',
            fontSize: '12px',
            fontWeight: 500,
            cursor: 'pointer',
          }}
        >
          {linkType == '' && socialShare == '' && adType == ''
            ? 'Starting From'
            : 'Price'}
        </div>
      </span>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 600,
          fontSize: '16px',
        }}
      >
        <p>
          $
          {filterType === 'contentOffering'
            ? linkType === '' && socialShare === '' && adType === ''
              ? startingPrice
              : cardData.listings[0].listingPrice
            : adType === ''
            ? startingPrice
            : cardData.listings[0].fixedPrice !== 0
            ? cardData.listings[0].fixedPrice
            : cardData.listings[0].minPrice}
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link
            to={
              linkType == '' && socialShare == '' && adType == ''
                ? `/listing/${cardData.user._id}`
                : `/listing/${cardData.user._id}?elementId=${cardData.listings[0]._id}`
            }
          >
            <AddCircleOutlineOutlinedIcon />
          </Link>
          {(linkType != '' || socialShare != '' || adType != '') &&
            (authUser == undefined || authUser.userType == 'ADVERTISER') &&
            (isItemInCart ? (
              <>
                &nbsp;&nbsp;&nbsp;
                <button onClick={handleRemoveFromCart}>
                  <BsFillCartCheckFill size={20} />
                </button>
              </>
            ) : (
              <>
                &nbsp;&nbsp;&nbsp;
                <button onClick={handleAddToCart}>
                  <BsCartPlus size={20} />
                </button>
              </>
            ))}
        </div>

        {/* {authUser?.userType === 'ADVERTISER' &&
          (isItemInCart ? (
            <button onClick={handleRemoveFromCart}>
              <CheckCircleIcon />
            </button>
          ) : (

          ))} */}
      </div>
    </div>
  )
}

export default MarketplaceCard
