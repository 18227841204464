import { Avatar } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AuthUserContext from '../../authentication/AuthUserContext'
import { addToCart } from '../../redux/actions'
import { useContext } from 'react'
import { removeFromCart } from '../../redux/actions'
import { addToCartAPI, deleteFromCartAPI } from '../../service/api.js'

function ListingCard(props) {
  const publisherImage = props.publisherImage
  const authUser = useContext(AuthUserContext)
  // const cookies = new Cookies()
  const cardData = props.cardData
  const cartData = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null
  const cartDataIds = cartData ? cartData.map((el) => el?.listingId) : []
  const dispatch = useDispatch()
  const [isItemInCart, setIsItemInCart] = useState(false)
  // console.log(isItemInCart)

  useEffect(() => {
    setIsItemInCart(false)
    if (cartDataIds.includes(cardData._id)) {
      setIsItemInCart(true)
    }
  }, [authUser, cardData])

  const handleAddToCart = async () => {
    const { _id, image, listingPrice } = cardData

    if (cartDataIds.includes(_id)) {
      alert('Already added to the cart')
      return
    }

    const payload = {
      listingId: _id,
      type: 'listing',
      name: cardData?.offerTitle,
      image,
      price: listingPrice,
    }

    dispatch(addToCart(payload))
    setIsItemInCart(true)

    const body = {
      listingId: _id,
      type: 'listing',
      userId: authUser._id,
    }

    const data = await addToCartAPI(body)
    // console.log(data)
  }

  const handleRemoveFromCart = async () => {
    const { _id } = cardData

    dispatch(removeFromCart(cardData))
    setIsItemInCart(false)
    const data = await deleteFromCartAPI(authUser._id, _id)
  }

  return (
    <div
      className="card"
      style={{
        width: '100%',
        // height: '167px',
        background: '#F8F8F8',
        padding: '15px 20px',
        // marginBottom: '30px',
        borderRadius: '5px',
      }}
    >
      <span>
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginTop: '12px',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '1rem',
          }}
        >
          <div
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{ width: '54px', height: '54px' }}
              src={publisherImage}
            />
          </div>
          <div
            className="cardTittle"
            style={{
              fontSize: '14px',
              fontWeight: 600,
            }}
          >
            <p>
              {cardData.offerTitle.split(' ').slice(0, 3).join(' ')}
              <br></br>
              {cardData.offerTitle.split(' ').slice(3).join(' ')}
            </p>
          </div>
        </div>

        <div
          style={{
            marginTop: '34px',
            color: '#4E4D4D',
            fontSize: '12px',
            fontWeight: 500,
            cursor: 'pointer',
          }}
        >
          Your Price
        </div>
      </span>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 600,
          fontSize: '16px',
        }}
      >
        <p>${cardData.listingPrice}</p>
      </div>
    </div>
  )
}

export default ListingCard
