import React, { useEffect } from 'react'
import '../listing/ppage.scss'
import '../Marketplace/marketplace.scss'
import '../listing/ppage.scss'
import { Avatar } from '@mui/material'
import { useState, useContext } from 'react'
import AuthUserContext from '../../authentication/AuthUserContext'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../redux/actions'
import { removeFromCart } from '../../redux/actions'
import { addToCartAPI, deleteFromCartAPI } from '../../service/api.js'
import '../Marketplace/marketplace.scss'
import { Block } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { BsCartPlus, BsFillCartCheckFill } from 'react-icons/bs'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

function ImageCard({
  listing,
  impressions,
  setImpressions,
  publisherImage,
  publisherName,
}) {
  const [userPrice, setUserPrice] = useState(
    listing.minPrice != 0 && listing.fixedPrice == 0
      ? listing.minPrice
      : listing.fixedPrice
  )

  const handlePriceAdd = () => {
    setUserPrice(userPrice + 500)
    const newImpressions = Math.round((userPrice / listing.cpm) * 1000)
    setImpressions((prevImpressions) => ({
      ...prevImpressions,
      [listing._id]: newImpressions.toLocaleString(),
    }))
  }

  const handlePriceSubtract = () => {
    if (userPrice == listing.minPrice) {
      return
    }

    setUserPrice(userPrice - 500)
    const newImpressions = Math.round((userPrice / listing.cpm) * 1000)
    setImpressions((prevImpressions) => ({
      ...prevImpressions,
      [listing._id]: newImpressions.toLocaleString(),
    }))
  }

  useEffect(() => {
    const initialImpressions = Math.round((userPrice / listing.cpm) * 1000)
    setImpressions((prevImpressions) => ({
      ...prevImpressions,
      [listing._id]: initialImpressions.toLocaleString(),
    }))
  }, [userPrice, listing.cpm, setImpressions])

  const cartData = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null
  const cartDataIds = cartData ? cartData.map((el) => el?.listingId) : []
  const authUser = useContext(AuthUserContext)

  const dispatch = useDispatch()
  const [isItemInCart, setIsItemInCart] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // console.log(cartDataIds)
    setIsItemInCart(false)
    if (cartDataIds.includes(listing._id)) {
      // console.log(true)
      setIsItemInCart(true)
    }
  }, [authUser, listing])

  const handleAddToCart = async () => {
    if (!authUser) {
      navigate('/sign-in')
      return
    }

    console.log('in handleaddtocart')
    console.log(listing)

    const { _id, image, listingPrice } = listing

    if (cartDataIds.includes(_id)) {
      alert('Already added to the cart')
      return
    }

    const payload = {
      listingId: _id,
      type: 'listing',
      name: listing?.offerTitle + ' (' + publisherName + ')',
      userId: authUser._id,
      image: publisherImage,
      price: userPrice,
    }
    console.log(payload)

    dispatch(addToCart(payload))
    setIsItemInCart(true)

    const body = {
      listingId: _id,
      type: 'listing',
      // name: listing?.offerTitle,
      name: listing?.offerTitle + ' (' + publisherName + ')',
      image: publisherImage,
      userId: authUser._id,
      price: userPrice,
    }

    const data = await addToCartAPI(body)
  }

  const handleRemoveFromCart = async () => {
    const { _id, image, listingPrice } = listing

    const payload = {
      listingId: _id,
      type: 'listing',
      // name: listing?.offerTitle,
      name: listing?.offerTitle + ' (' + publisherName + ')',

      userId: authUser._id,
      image: publisherImage,
      price: userPrice,
    }

    dispatch(removeFromCart(payload))
    setIsItemInCart(false)
    const data = await deleteFromCartAPI({
      userId: authUser._id,
      type: 'listing',
      listingId: listing._id,
    })
  }

  console.log('image card listing', listing)

  return (
    <div className="left imageCard">
      <div className="headExp">
        <span className="image">
          <Avatar sx={{ width: '65px', height: '65px' }} src={publisherImage} />
        </span>
        <span className="listTit">{listing.offerTitle}</span>
      </div>

      <span className="data">
        {listing.image != '' && listing.image != undefined && (
          <div className="publisherCardImage" style={{ display: Block }}>
            <img
              src={listing.image}
              style={{
                width: '100%',
                height: '100%',
              }}
              alt=""
            />
          </div>
        )}

        <div className="ppPrice">
          <div className="topSticky">
            <div className="stickyBottom">
              <div className="stickyBottom_left">
                {listing.minPrice != 0 &&
                  listing.fixedPrice == 0 &&
                  (authUser == undefined ||
                    authUser.userType == 'ADVERTISER') && (
                    <button onClick={handlePriceSubtract}>
                      <RemoveCircleOutlineIcon />
                    </button>
                  )}
                <strong>${userPrice}</strong>
                {listing.minPrice != 0 &&
                  listing.fixedPrice == 0 &&
                  (authUser == undefined ||
                    authUser.userType == 'ADVERTISER') && (
                    <button onClick={handlePriceAdd}>
                      <AddCircleOutlineIcon />
                    </button>
                  )}
              </div>

              {(authUser == undefined || authUser.userType == 'ADVERTISER') &&
                (isItemInCart ? (
                  <button onClick={handleRemoveFromCart}>
                    <BsFillCartCheckFill size={25} />
                  </button>
                ) : (
                  <button onClick={handleAddToCart}>
                    <BsCartPlus size={25} />
                  </button>
                ))}
            </div>
          </div>
        </div>
      </span>
    </div>
  )
}

export default ImageCard
