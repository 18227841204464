import React, { useEffect, useState, useContext } from 'react'
import './orderHistory.scss'
import { getAllOrderHistory } from '../../service/api.js'
import AuthUserContext from '../../authentication/AuthUserContext'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

function OrderHistory() {
  function toSentenceCase(str) {
    return str
      .toLowerCase()
      .replace(/(^\s*\w|[\.\!\?]\s*\w)/g, (c) => c.toUpperCase())
  }
  const [orderHeader, setOrderHeader] = useState([])
  const [orderData, setOrderData] = useState([])
  const authuser = useContext(AuthUserContext)

  useEffect(() => {
    if (authuser) {
      getAllOrderHistory(authuser._id)
        .then(({ orderHeader, orderData }) => {
          setOrderHeader(orderHeader)
          setOrderData(orderData)
        })
        .catch((err) => {
          // console.log(err)
        })
    }
  }, [authuser])

  const tableCellStyle = {
    border: '1px solid black',
    textAlign: 'center',
    paddingLeft: '30px',
    width: '25%',
    padding: '10px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
  }

  const OrdersTable = () => {
    return (
      <div>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            padding: '0 20px',
            fontFamily: 'Inter, sans-serif',
          }}
        >
          <thead>
            <tr>
              {orderHeader.map((header) => (
                <th
                  key={header}
                  style={{
                    ...tableCellStyle,
                    width: header === 'amount' ? '20%' : '25%',
                    fontWeight: '600',
                  }}
                >
                  {toSentenceCase(header)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orderData.map((data) => (
              <tr key={data.id}>
                <td
                  style={{
                    ...tableCellStyle,
                    textAlign: 'center',
                    width: '25%',
                  }}
                >
                  {new Date(data?.date).toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </td>
                <td style={tableCellStyle}>{data.id}</td>
                <td
                  style={{
                    ...tableCellStyle,
                    textAlign: 'start',
                    width: '30%',
                  }}
                >
                  {data.description}
                </td>
                <td
                  style={{
                    ...tableCellStyle,
                    width: '20%',
                  }}
                >
                  {data.amount}
                </td>
                <td style={tableCellStyle}>{data.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const tableCellStyle_mob = {
    border: '0.4px solid black',
    textAlign: 'center',
    width: '25%',
    padding: '0px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
  }

  const OrdersTable_mob = () => {
    return (
      <div>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <Table
          sx={{ width: '100%' }}
          className="vT"
          aria-label="customized table"
        >
          {orderData?.map((row) => {
            return (
              <>
                <TableRow>
                  <TableCell
                    className="verticalTableId"
                    variant="head"
                    align="center"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="verticalTableValue"
                    sx={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {new Date(row?.date).toLocaleDateString('en-US', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="verticalTableId"
                    variant="head"
                    align="center"
                  >
                    ID
                  </TableCell>
                  <TableCell
                    className="verticalTableValue"
                    sx={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {row?.id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="verticalTableId"
                    variant="head"
                    align="center"
                  >
                    Description
                  </TableCell>
                  <TableCell
                    className="verticalTableValue"
                    sx={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {row?.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="verticalTableId"
                    variant="head"
                    align="center"
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    className="verticalTableValue"
                    sx={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {row?.amount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="verticalTableId"
                    variant="head"
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className="verticalTableValue"
                    sx={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {row?.status}
                  </TableCell>
                </TableRow>
                <br />
              </>
            )
          })}
        </Table>
      </div>
    )
  }

  return (
    <div className="card_Order">
      <div className="cardOrder">
        <div className="headerOrder">
          <span>Order History</span>
        </div>
        <span className="dataOrder">
          <div className="md:hidden sm:block">
            {orderHeader?.length > 0 ? (
              <OrdersTable_mob />
            ) : (
              'No Data Available'
            )}
          </div>
          <div className="hidden md:block">
            {orderHeader?.length > 0 ? <OrdersTable /> : 'No Data Available'}
          </div>
        </span>
      </div>
    </div>
  )
}

export default OrderHistory
