import React from 'react'
import { useState, useEffect } from 'react'
import WalletPublisherTable from './walletTable'
import WalletPublisherTableVertical from './walletTableVertical'
import './walletHistory.scss'
import { getWalletHistoryAll, addWalletRequest } from '../../service/api.js'
import { snackbarNotification } from '../../redux/snackbar_actions'
import { useDispatch } from 'react-redux'
import { useContext } from 'react'
import AuthUserContext from '../../authentication/AuthUserContext'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

function WalletHistory() {
  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }

  const init = {
    method: '',
    amount: '',
  }

  const [input, setInput] = useState({
    method: 'bankTransfer',
    amount: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setInput({ ...input, [name]: value })
  }
  const authuser = useContext(AuthUserContext)
  const [walletHistory, setWalletHistory] = useState([])
  const [withdrawAmount, setWithdrawAmount] = useState()
  const [currentBalance, setCurrentBalance] = useState(
    walletHistory.currentBalance
  )

  const fetchWalletHistory = async (userId) => {
    const history = await getWalletHistoryAll(userId)
    setWalletHistory(history)
  }

  useEffect(() => {
    if (authuser) {
      fetchWalletHistory(authuser._id)
    }
  }, [authuser])

  // console.log(walletHistory)
  const dispatch = useDispatch()

  const handleWithdrawal = async () => {
    const { method, amount } = input

    if (walletHistory.currentBalance === 0) {
      const notificationData = {
        notificationType: 'error',
        notificationMessage: 'Insufficient Current Balance',
      }
      dispatch(snackbarNotification(notificationData))
      return
    }
    if (!authuser) {
      const notificationData = {
        notificationType: 'error',
        notificationMessage: 'User not authenticated',
      }
      dispatch(snackbarNotification(notificationData))
      return
    }

    try {
      const response = await addWalletRequest(amount, method, authuser._id)
      if (!response?.data?.success) {
        const notificationData = {
          notificationType: 'error',
          notificationMessage: response?.data?.message,
        }
        dispatch(snackbarNotification(notificationData))
        return
      }
      setWithdrawAmount(response.data.withdrawRequestData)
      setCurrentBalance(walletHistory.currentBalance - amount)

      walletHistory.currentBalance = currentBalance

      const notificationData = {
        notificationType: 'success',
        notificationMessage: response.data.message,
      }
      dispatch(snackbarNotification(notificationData))
      setInput(init)
    } catch (error) {
      const notificationData = {
        notificationType: 'error',
        notificationMessage: error.message,
      }
      dispatch(snackbarNotification(notificationData))
    }
  }

  const handleClick = async () => {
    await handleWithdrawal()
    if (authuser) {
      fetchWalletHistory(authuser._id)
    }
  }

  const isProceedButtonDisabled = () => {
    const { method, amount } = input
    const { currentBalance } = walletHistory

    return (
      currentBalance === 0 ||
      currentBalance < amount ||
      (method !== 'bankTransfer' && method !== 'crypto') ||
      amount <= 0
    )
  }

  return (
    <div className="WalletPublisher">
      <div className="head">
        <div className="left">
          Current Wallet Balance :${walletHistory.currentBalance}
        </div>
        <div className="right">
          Pending Withdrawal : ${walletHistory.pendingWithdrawal}
        </div>
      </div>
      <div className="content">
        <div className="cLeft">
          <div className="mainHeading">Withdraw Funds</div>
          <div className="subHeading">Please enter the amount</div>
          <input
            className="wInput"
            type="number"
            placeholder="Enter Withdrawal Amount"
            name="amount"
            value={input?.amount}
            onChange={handleChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          ></input>
          <div className="subHeading sub1">Choose your payment method</div>
          <div className="wInput">
            <label htmlFor="bankTransfer">Bank Transfer</label>
            <input
              type="radio"
              name="method"
              value="bankTransfer"
              id="bankTransfer"
              checked={input.method === 'bankTransfer'}
              onChange={handleChange}
            ></input>
          </div>
          <div className="wInput method">
            <label htmlFor="crypto">Cryptocurrency</label>
            <input
              type="radio"
              name="method"
              value="crypto"
              id="crypto"
              checked={input.method === 'crypto'}
              onChange={handleChange}
            ></input>
          </div>

          <>
            {isProceedButtonDisabled() ? (
              <p style={{ color: 'red', marginTop: '10px' }}>
                {isInputFocused && walletHistory.currentBalance === 0
                  ? 'Insufficient Current Balance'
                  : ''}
                {isInputFocused &&
                input?.amount &&
                input?.amount > walletHistory.currentBalance
                  ? 'Amount exceeds current balance'
                  : ''}
                {isInputFocused && (!input?.amount || input?.amount <= 0)
                  ? 'Amount must be greater than zero'
                  : ''}
                {isInputFocused &&
                input?.method !== 'bankTransfer' &&
                input?.method !== 'crypto'
                  ? 'Invalid withdrawal method'
                  : ''}
              </p>
            ) : (
              <button
                onClick={handleClick}
                style={{ borderRadius: '5px' }}
                className="proceed"
              >
                Proceed
                <ArrowForwardIcon
                  sx={{
                    marginLeft: '6px',
                    marginBottom: '3px',
                    fontSize: '18px !important',
                  }}
                />
              </button>
            )}
          </>
        </div>
        <div className="cRight">
          <div className="mainHeading">Wallet History</div>
          <div className="walletTableComponent">
            <div className="hidden xl:block">
              {walletHistory.orderHistory?.length > 0 ? (
                <WalletPublisherTable data={walletHistory.orderHistory} />
              ) : (
                'No Data Available'
              )}
            </div>
            <div className="xl:hidden sm:block">
              {walletHistory.orderHistory?.length > 0 ? (
                <WalletPublisherTableVertical
                  data={walletHistory.orderHistory}
                />
              ) : (
                'No Data Available'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WalletHistory
