import './authenticateLogin.scss'
import { Slide, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ReplayIcon from '@mui/icons-material/Replay'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import {
  verifyOtpAPI,
  resendOtpAPI,
  changeEmailAPI,
} from '../../service/api.js'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useNavigate } from 'react-router-dom'
import AuthUserContext from '../../authentication/AuthUserContext'
import { snackbarNotification } from '../../redux/snackbar_actions.js'
import { useDispatch } from 'react-redux'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function AuthenticateLogin() {
  const authuser = useContext(AuthUserContext)
  const dispatch = useDispatch()
  useEffect(() => {
    if (authuser != null) {
      setEmail(authuser.email)
      if (authuser.emailVerified == false) {
        setOpen(true)
      }
    }
  }, [authuser])

  const [emailOtp, setEmailOtp] = useState()
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = useState()
  const [isEmailEdit, setIsEmailEdit] = useState(false)
  const [resendOtp, setResendOtp] = useState(false)
  const navigate = useNavigate()
  const handleEmailOtp = (e) => {
    setEmailOtp(e.target.value)
  }

  const handleClose = (event, reason) => {
    // console.log(event === 'backdropClick', reason, 'event')
    if (reason && reason == 'backdropClick') return
    setOpen(false)
    navigate('/sign-in')
  }

  const submitOtp = async () => {
    try {
      verifyOtpAPI(emailOtp).then((resp) => {
        dispatch(snackbarNotification(resp))
        // console.log(resp)
        if (resp.notificationType == 'success') {
          setOpen(false)
        }
      })
    } catch (error) {
      // console.log(error)
    }
  }

  const handleResendOtp = async () => {
    try {
      resendOtpAPI(email, authuser._id).then((resp) => {
        dispatch(snackbarNotification(resp))
        // console.log(resp)
      })
    } catch (error) {
      // console.log(error)
    }
  }

  const toggleEmailBtn = () => {
    setIsEmailEdit(true)
  }

  const submitChangeEmail = async () => {
    try {
      changeEmailAPI(
        email,
        authuser.jwtToken,
        authuser._id,
        setEmail,
        authuser.email
      ).then((resp) => {
        // console.log(resp)
        dispatch(snackbarNotification(resp))
        setIsEmailEdit(false)
        if (resp.notificationType == 'success') {
          window.location.reload(false)
          handleResendOtp()
        }
      })
    } catch (error) {
      // console.log(error)
    }
  }

  return (
   
    <Dialog
      open={open}
     
      TransitionComponent={Transition}
      keepMounted
      disableEscapeKeyDown
      onClose={handleClose}
      // sx={{ backgroundImage: '#f9f9f9 !important',margin:'10px !important' }}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ square: true }}
    >
      <DialogContent  className="pop">
        <div className="popups">

            <DialogTitle className="titles">
              Add Your Verification Code
            </DialogTitle>
            <div className="inputs">
              <div className="emailButtons">
                {/* <span> */}
                <input
                  disabled={!isEmailEdit}
                  className="ips"
                  name="email"
                  value={email}
                  type="texts"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={'Your Email'}
                />
                <button
                  type="submit"
                  onClick={!isEmailEdit ? toggleEmailBtn : submitChangeEmail}
                  className="submits"
                >
                  {!isEmailEdit ? (
                    <>
                      Change
                      <TrackChangesIcon
                        sx={{
                          marginLeft: '6px',
                          marginBottom: '3px',
                          fontSize: '18px !important',
                        }}
                      />
                    </>
                  ) : (
                    <>
                      Submit
                      <ArrowForwardIcon
                        sx={{
                          marginLeft: '6px',
                          marginBottom: '3px',
                          fontSize: '18px !important',
                        }}
                      />
                    </>
                  )}
                </button>
              </div>
              <input
                className="ips"
                name="emailOtp"
                value={emailOtp}
                type="texts"
                onChange={handleEmailOtp}
                placeholder={'Enter OTP'}
              />
            </div>
            <span className="twoButton" style={{ display: 'flex' }}>
              <button
                type="submit"
               
                onClick={submitOtp}
                className="submits"
              >
                Submit{' '}
                <ArrowForwardIcon
                  sx={{
                    marginLeft: '6px',
                    marginBottom: '3px',
                    fontSize: '18px !important',
                  }}
                />
              </button>
              <button
                type="submit"
               
                onClick={handleResendOtp}
                // onClick={resendClicked}
                className="submits"
              >
                Resend
                <ReplayIcon
                  sx={{
                    marginLeft: '6px',
                    marginBottom: '3px',
                    fontSize: '18px !important',
                  }}
                />
              </button>
            </span>

            <div
              className="lastLine"
            >
              Facing issues?
              <a
                href="https://www.koinpr.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Contact support
              </a>
            </div>
          </div>

      </DialogContent>
    </Dialog>
 
  )
}

export default AuthenticateLogin
