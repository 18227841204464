import React from 'react'
import '../listing/ppage.scss'
import '../Marketplace/marketplace.scss'

import '../listing/ppage.scss'
import { Avatar } from '@mui/material'
import { useState, useContext, useEffect } from 'react'
import AuthUserContext from '../../authentication/AuthUserContext'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../redux/actions'
import { removeFromCart } from '../../redux/actions'
import { addToCartAPI, deleteFromCartAPI } from '../../service/api.js'
import '../Marketplace/marketplace.scss'
import { useNavigate } from 'react-router-dom'
import { BsCartPlus, BsFillCartCheckFill } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'

function NormalCard(props) {
  const publisherImage = props.publisherImage
  const publisherName = props.publisherName

  const location = useLocation()
  const listing = props.listing
  console.log(listing)
  const cartData = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null
  const cartDataIds = cartData ? cartData.map((el) => el?.listingId) : []
  const authUser = useContext(AuthUserContext)

  const dispatch = useDispatch()
  const [isItemInCart, setIsItemInCart] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // console.log(cartDataIds)
    setIsItemInCart(false)
    if (cartDataIds.includes(listing._id)) {
      // console.log(true)
      setIsItemInCart(true)
    }
  }, [authUser, listing])

  const handleAddToCart = async () => {
    if (!authUser) {
      navigate('/sign-in', { state: { from: location } })
      return
    }

    const { _id, image, listingPrice } = listing

    if (cartDataIds.includes(_id)) {
      alert('Already added to the cart')
      return
    }

    const payload = {
      listingId: _id,
      type: 'listing',
      // name: listing?.offerTitle,
      name: listing?.offerTitle + ' (' + publisherName + ')',

      userId: authUser._id,
      image: publisherImage,
      price: listingPrice,
    }
    console.log(payload)

    dispatch(addToCart(payload))
    setIsItemInCart(true)

    const body = {
      listingId: _id,
      type: 'listing',
      userId: authUser._id,
      // name: listing?.offerTitle,
      name: listing?.offerTitle + ' (' + publisherName + ')',

      image: publisherImage,
      price: listingPrice,
    }

    const data = await addToCartAPI(body)
    console.log('item added........', data)
  }

  const handleRemoveFromCart = async () => {
    const { _id, image, listingPrice } = listing

    const payload = {
      listingId: _id,
      type: 'listing',
      // name: listing?.offerTitle,
      name: listing?.offerTitle + ' (' + publisherName + ')',
      userId: authUser._id,
      image: publisherImage,
      price: listingPrice,
    }

    dispatch(removeFromCart(payload))
    setIsItemInCart(false)
    const data = await deleteFromCartAPI({
      userId: authUser._id,
      type: 'listing',
      listingId: listing._id,
    })
    console.log('removed...', data)
  }

  return (
    <div className="left normalCard">
      <div className="headExp">
        <span className="image">
          <Avatar sx={{ width: '65px', height: '65px' }} src={publisherImage} />
        </span>
        <span className="listTit">{listing.offerTitle}</span>
      </div>
      <div className="headExp-data">
        <span className="data">
          <span className="listTitR" style={{ display: 'none' }}>
            {listing.offerTitle}
          </span>
        </span>
        <div className="normalCardPrice">
          <strong>${listing.listingPrice}</strong>
          {(authUser == undefined || authUser.userType == 'ADVERTISER') &&
            (isItemInCart ? (
              <button onClick={handleRemoveFromCart}>
                <BsFillCartCheckFill size={25} />
              </button>
            ) : (
              <button onClick={handleAddToCart}>
                <BsCartPlus size={25} />
              </button>
            ))}
        </div>
      </div>
    </div>
  )
}

export default NormalCard
