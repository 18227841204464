import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-lg">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900">404 - Page Not Found</h1>
          <p className="mt-4 text-lg text-gray-500">Sorry, we couldn't find the page you were looking for.</p>
        </div>
        <div className="flex justify-center">
          <Link
            to="/"
            className="mt-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Go back to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
