import React from 'react'
import { useState, useContext } from 'react'
import { addTelegramAPI } from '../../service/api.js'
import './addYourTelegram.scss'
import AuthUserContext from '../../authentication/AuthUserContext'
import { snackbarNotification } from '../../redux/snackbar_actions.js'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogActions } from '@material-ui/core'
import { Slide } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AddYourTelegram = () => {
  const [telegramId, setTelegramId] = useState('')
  const [msg, setMsg] = useState('')
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const authuser = useContext(AuthUserContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(telegramId)
    try {
      addTelegramAPI(telegramId, authuser.jwtToken, authuser._id).then(
        (response) => {
          // console.log(response)
          dispatch(snackbarNotification(response))
          if (response.notificationType == 'success') {
            navigate('/')
          }
        }
      )
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <>
      <Telegram
        value1={telegramId}
        setValue1={setTelegramId}
        handleSubmit={handleSubmit}
      />
      {/* <CommonPopup
        title="Add Your Telegram"
        error={error}
        msg={msg}
        value1={telegramId}
        setValue1={setTelegramId}
        input1="Your telegram @username"
        buttonText="Submit"
        handleSubmit={handleSubmit}
      /> */}
    </>
  )
}

export default AddYourTelegram

function Telegram(props) {
  const [value, setValue] = useState(true)
  const navigate = useNavigate()

  const handleClose = (event, reason) => {
    // console.log(event === 'backdropClick', reason, 'event')
    if (reason && reason == 'backdropClick') return
    setValue(false)
    navigate('/')
  }
  return (
    <div className="telegram_div">
      <Dialog
        open={value}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
        onClose={handleClose}
        sx={{ backgroundImage: '#f9f9f9 !important' }}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ square: true }}
      >
        <DialogContent className="pop">
          <div className="popups">
            <DialogActions>
              <CloseIcon onClick={handleClose}></CloseIcon>
              {/* <Button onClick={handleClose}>Close</Button> */}
            </DialogActions>
            <DialogTitle className="titles">Add Your Telegram</DialogTitle>
            <div className="inputs">
              <input
                className="ips"
                name="emailOtp"
                value={props.value1}
                type="texts"
                onChange={(e) => props.setValue1(e.target.value)}
                placeholder={'Enter Username'}
              />
            </div>
            <span className="twoButton" style={{ display: 'flex' }}>
              <button
                type="submit"
                onClick={props.handleSubmit}
                className="submits"
              >
                Submit{' '}
                <ArrowForwardIcon
                  sx={{
                    marginLeft: '6px',
                    marginBottom: '3px',
                    fontSize: '18px !important',
                  }}
                />
              </button>
            </span>

            <div className="lastLine">
              This will help your account manager to commute with you faster.
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
