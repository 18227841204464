import React, { useContext, useEffect, useState } from 'react'
import PackageCardNew from './newPackageCard'
import { useDispatch } from 'react-redux'
import AuthUserContext from '../../authentication/AuthUserContext'
import { getPackage } from '../../service/api'
import { activateCart } from '../../redux/actions'

function NewPackage() {
  const [activeButton, setActiveButton] = useState('Press Release')

  const [loading, setLoading] = useState(false)
  const [originalCardList, setOriginalCardList] = useState([])
  const [cardList, setCardList] = useState([])
  const [cartDataIds, setCartDataIds] = useState([])
  const authUser = useContext(AuthUserContext)
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState()

  useEffect(() => {
    if (authUser) {
      const cartData = localStorage.getItem('cart')
        ? JSON.parse(localStorage.getItem('cart'))
        : null
      setCartDataIds(cartData ? cartData.map((el) => el?.id) : [])
      dispatch(activateCart())
    }
  }, [authUser])

  useEffect(() => {
    getPackageData()
  }, [])

  const getPackageData = () => {
    setLoading(true) // add this line
    getPackage().then((res) => {
      console.log(res)
      setOriginalCardList(res)
      setCardList(res)
      setLoading(false) // add this line
    })
  }

  useEffect(() => {
    const filteredArray = originalCardList?.filter(
      (obj) => obj.categoryType === activeButton
    )
    setCardList(filteredArray)
    console.log('filtered card list for ', activeButton)
    console.log(filteredArray)
  }, [activeButton, originalCardList])

  const ToggleButton = ({ label }) => (
    <button
      className={`w-full p-2  md:p-3 rounded-[3rem] font-semibold text-xs sm:text-sm  md:text-md xl:text-md transition-colors duration-300 ease-in-out font-inter ${
        activeButton === label
          ? 'bg-[#4353FF] text-white'
          : 'bg-transparent text-[#4353FF] hover:font-extrabold'
      }`}
      onClick={() => setActiveButton(label)}
    >
      {label}
    </button>
  )

  return (
    <div className="mb-24">
      <div className="flex flex-col-reverse  lg:gap-0 lg:flex-row  justify-between px-5 xs:px-8  py-8 pt-0   md:px-8 md:py-16  lg:px-12 lg:py-16 border-b-[1.5px] border-black font-inter">
        <div className="flex flex-col gap-3 md:gap-6 lg:gap-6 justify-center px-0  py-4 md:py-0  md:px-5">
          <h1 className="text-2xl xs:text-3xl sm:text-4xl  md:text-5xl  font-semibold font-inter">
            Find your perfect package
          </h1>
          <h3 className="text-sm sm:text-md  md:text-[1.24rem] lg:text-[1.4rem] font-inter">
            You save more than 15% in packages
          </h3>
          <a
            href="http://koinpr.com/contact"
            target="_blank"
            rel="noopener noreferrer"
            className="font-inter decoration-none bg-black rounded-sm border-white border text-white px-4 py-2 text-sm md:text-lg w-fit mt-1.5 hover:bg-white hover:text-black hover:border-black "
          >
            Need Assistance
          </a>
        </div>

        <div className="flex justify-start lg:justify-end lg:mr-12 items-center">
          <img
            src="/Untitled design-112 1.png"
            className="w-3/4 xs:w-2/3 sm:w-1/2  h-auto lg:w-[380px] lg:h-11/12"
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col px-4 xs:p-8 py-12 md:p-10 xl:p-14">
        <div className="flex flex-col lg:flex-row md:px-5 lg:justify-start items-center gap-6 md:gap-12 mb-20 md:mb-28 xl:mb-36">
          <h1 className="text-xl md:text-3xl text-center lg:text-left  font-semibold font-inter">
            Choose package type
          </h1>
          <div className="flex w-full sm:w-3/4 lg:w-1/2 gap-2 items-center rounded-[3rem] justify-around p-2 md:py-2  md:px-3 bg-[#ECEEFF]">
            <ToggleButton label="Press Release" />
            <ToggleButton label="Sponsored" />
            <ToggleButton label="Organic" />
          </div>
        </div>
        <div className="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-x-6 sm:gap-y-12  md:gap-8 xl:gap-16">
          {cardList?.map((item, i) => {
            return (
              <PackageCardNew item={item} popular={item?.type === 'coloured'} />
            )
          })}

          {/* <PackageCardNew popular={true} />
          <PackageCardNew /> */}
        </div>
      </div>
    </div>
  )
}

export default NewPackage
