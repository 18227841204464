import { Routes, Route } from 'react-router-dom'
import SignIn from './components/SignIn/signIn.js'
import SignUp from './components/SignUp/signUp.js'
import ResetPassword from './components/ResetPassword/resetPassword.js'
import AuthenticateLogin from './components/AuthenticateLogin/authenticateLogin.js'
import SetPassword from './components/SetPassword/setPassword.js'
import AddYourTelegram from './components/AddTelegram/addYourTelegram.js'
import Marketplace from './components/Marketplace/marketplace.js'
import withAuthentication from './authentication/withAuthentication.js'
import AccountDetails from './components/AccountDetails/accountDetails.js'
import Listing from './components/listing/Listing.js'
import OrderHistory from './components/orderHistory/orderHistory.js'
import WalletHistory from './components/walletHistory/walletHistory.js'
import CartCheckout from './components/cartCheckout/cartCheckout.js'
import NotFound from './components/NotFound/notFound.js'
import CheckoutSuccess from './components/cartCheckout/stripe/checkoutSuccess'
import Header from './components/Header/header.js'
import { SnackbarNotification } from './components/Snackbar/snackbar.js'
import PublisherPage from './components/publisherPage/publisherPage.js'
import Footer from './components/Footer/footer'
import { Helmet } from 'react-helmet'
import NewPackage from './components/newPack/newPackage.js'
function App() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-K0CX24H3LF"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-K0CX24H3LF');
        `}
        </script>
        <script>
          {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3524509,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
        </script>
      </Helmet>
      <div className="Main" style={{ minHeight: '100vh' }}>
        <Header />
        <SnackbarNotification />
        <AuthenticateLogin />
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          {/* <Route path="/add-listing" element={<AddListing />} /> */}
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-password/:id/:token" element={<SetPassword />} />
          <Route path="/add-telegram" element={<AddYourTelegram />} />
          <Route path="/" element={<Marketplace />} />
          <Route path="/profile" element={<AccountDetails />} />
          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/publisherPage" element={<PublisherPage />} />
          <Route path="/packages" element={<NewPackage />} />
          {/* <Route path="/package-new" element={<NewPackage />} /> */}
          <Route path="/wallet-history" element={<WalletHistory />} />
          <Route path="/cart-checkout" element={<CartCheckout />} />
          <Route
            path="/checkout-successful/:id"
            element={<CheckoutSuccess />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <div className="foo"></div> */}
        <Footer />
      </div>
    </>
  )
}

export default withAuthentication(App)
// export default App;
