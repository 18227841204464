import React from 'react'

import { useState } from 'react'
import './resetPassword.scss'
import { resetPasswordAPI } from '../../service/api.js'
import { snackbarNotification } from '../../redux/snackbar_actions.js'
import { useDispatch } from 'react-redux'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      resetPasswordAPI(email).then((response) => {
        // console.log(response)
        dispatch(snackbarNotification(response))
      })
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <div className="resetpopup">
      <div className="content">
        <div className="title">Reset Your Password</div>
        <div className="input">
          <input
            className="ip"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Your Registered Email"
          />
        </div>
        <button type="submit" onClick={handleSubmit} className="submit">
          Submit
          <ArrowForwardIcon
            sx={{ fontSize: '18px', marginLeft: '4px', marginBottom: '2px' }}
          />
        </button>
        <div className="bottom">
          Facing issues?{' '}
          <a href="https://www.koinpr.com/contact" target="_blank">
            Contact support{' '}
            <ArrowForwardIcon
              sx={{ fontSize: '18px', marginLeft: '1px', marginBottom: '2px' }}
            />
          </a>{' '}
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
