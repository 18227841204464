import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import './withdrawlOption.scss'
import { Constant } from '../../common.js'
import { useNavigate } from 'react-router-dom'
import { getUserByToken, saveUserDetails } from '../../service/api'
import Loader from '../Loader/Loader'

function WithdrawlOption() {
  const navigate = useNavigate()

  const [authUser, setAuthUser] = useState(null)
  const [cryptoWalletAddress, setCryptoWalletAddress] = useState('')

  const [bankBeneficiaryName, setBankBeneficiaryName] = useState('')
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [bankSwiftCode, setBankSwiftCode] = useState('')
  const [bankName, setBankName] = useState('')
  const [loading, setLoading] = useState(false)
  const [cryptoWithdrawlOption, setCryptoWithdrawlOption] = useState(true)
  const [bankWithdrawlOption, setBankWithdrawlOption] = useState(false)

  useEffect(() => {
    ;(async () => {
      const response = await getUserByToken()
      setAuthUser(response)
    })()
  }, [])

  useEffect(() => {
    if (authUser?.bankTransfer) {
      if (authUser?.accountNo) {
        setBankAccountNumber(authUser.accountNo)
      }
      if (authUser?.fullName) {
        setBankBeneficiaryName(authUser.fullName)
      }
      if (authUser?.swiftCode) {
        setBankSwiftCode(authUser.swiftCode)
      }
      if (authUser?.bankName) {
        setBankName(authUser.bankName)
      }
    }
    if (authUser?.walletAddress) {
      setCryptoWalletAddress(authUser.walletAddress)
    }
  }, [authUser])

  const handleChange = (key, event) => {
    switch (key) {
      case Constant.CRYPTO_WALLET_ADDRESS:
        setCryptoWalletAddress(event.target.value)
        break
      case Constant.BANK_BENEFICIARY_NAME:
        setBankBeneficiaryName(event.target.value)
        break
      case Constant.BANK_ACCOUNT_NUMBER:
        setBankAccountNumber(event.target.value)
        break
      case Constant.BANK_SWIFT_CODE:
        setBankSwiftCode(event.target.value)
        break
      case Constant.BANK_NAME:
        setBankName(event.target.value)
        break
    }
  }

  const handleClick = (key) => {
    switch (key) {
      case Constant.CRYPTO_WITHDRAWL_OPTION:
        setCryptoWithdrawlOption(true)
        setBankWithdrawlOption(false)
        break
      case Constant.BANK_WITHDRAWL_OPTION:
        setCryptoWithdrawlOption(false)
        setBankWithdrawlOption(true)
        break
    }
  }

  const getPayload = () => {
    if (cryptoWalletAddress) {
      if (
        bankAccountNumber &&
        bankBeneficiaryName &&
        bankName &&
        bankSwiftCode
      ) {
        return {
          fullName: bankBeneficiaryName,
          accountNo: bankAccountNumber,
          swiftCode: bankSwiftCode,
          bankName: bankName,
          bankTransfer: true,
          walletAddress: cryptoWalletAddress,
          cryptoCurrency: true
        }
      } else {
        return {
          walletAddress: cryptoWalletAddress,
          cryptoCurrency: true
        }
      }
    }

    return {
      fullName: bankBeneficiaryName,
      accountNo: bankAccountNumber,
      swiftCode: bankSwiftCode,
      bankName: bankName,
      bankTransfer: true,
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    const request = getPayload()
    const response = await saveUserDetails(authUser._id, request)
    setAuthUser(response)
    setLoading(false)
    navigate('/')
  }

  const checkButtonToDisable = () => {
    if (authUser?.userVerified) {
      return true
    }

    if (cryptoWalletAddress.trim()) {
      return false
    }
    if (
      bankAccountNumber.trim() &&
      bankBeneficiaryName.trim() &&
      bankSwiftCode.trim() &&
      bankName.trim()
    ) {
      return false
    }
    return true
  }

  return (
    <>
      {loading && <Loader />}
      <Grid sx={{ width: '100%', background: '#f9f9f9' }}>
        <div className="withdrawlOption">
          <Grid item xs={12} className="head">
            <p sx={{ fontWeight: '600', fontSize: '18px' }}>
              Withdrawal Option
            </p>
          </Grid>
          <Grid item xs={12} className="status">
            <p>Select Your Withdrawal Method</p>
          </Grid>
          <Grid container columnSpacing={3}>
            <Grid
              sx={{ marginTop: '20px', marginLeft: '0px' }}
              item
              md={6}
              xs={12}
            >
              <div className="checkBox">
                <label className="label">Cryptocurrency</label>
                <input
                  id="crypto"
                  type="radio"
                  name="account"
                  className="round"
                  checked={cryptoWithdrawlOption}
                  onChange={() => handleClick(Constant.CRYPTO_WITHDRAWL_OPTION)}
                />
              </div>
            </Grid>
            <Grid
              sx={{ marginTop: '20px', marginLeft: '0px' }}
              item
              md={6}
              xs={12}
            >
              <div className="checkBox">
                <label className="label">Bank Transfer/SWIFT</label>
                <input
                  id="bank"
                  type="radio"
                  name="account"
                  className="round"
                  checked={bankWithdrawlOption}
                  onChange={() => handleClick(Constant.BANK_WITHDRAWL_OPTION)}
                />
              </div>
            </Grid>
          </Grid>
          {cryptoWithdrawlOption ? (
            <>
              <Grid item xs={12} className="status">
                <p sx={{ fontWeight: '500', marginTop: '20px' }}>
                  Enter Your Wallet Details
                </p>
              </Grid>
              <Grid container columnSpacing={3} sx={{ marginTop: '0px' }}>
                <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                  <input
                    placeholder="Token Type: USDT (TRC20)"
                    type="input"
                    name="account"
                    disabled={true}
                    className="input"
                    style={{ width: '100%' }}
                  />
                </Grid>

                <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                  <input
                    className="input"
                    type="input"
                    value={cryptoWalletAddress}
                    disabled={authUser?.userVerified}
                    onChange={(e) =>
                      handleChange(Constant.CRYPTO_WALLET_ADDRESS, e)
                    }
                    name="account"
                    style={{ width: '100%' }}
                    placeholder="Wallet Address"
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} className="status">
                <p sx={{ fontWeight: '500', marginTop: '20px' }}>
                  Enter your bank details
                </p>
              </Grid>
              <Grid container columnSpacing={3} sx={{ marginTop: '0px' }}>
                <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                  <input
                    placeholder="Beneficiary Name"
                    type="input"
                    name="account"
                    value={bankBeneficiaryName}
                    disabled={authUser?.userVerified}
                    onChange={(e) =>
                      handleChange(Constant.BANK_BENEFICIARY_NAME, e)
                    }
                    className="input"
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                  <input
                    className="input"
                    type="input"
                    name="account"
                    value={bankAccountNumber}
                    disabled={authUser?.userVerified}
                    onChange={(e) =>
                      handleChange(Constant.BANK_ACCOUNT_NUMBER, e)
                    }
                    style={{ width: '100%' }}
                    placeholder="IBAN/Account No."
                  />
                </Grid>
                <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                  <input
                    placeholder="SWIFT Code"
                    type="input"
                    name="account"
                    value={bankSwiftCode}
                    disabled={authUser?.userVerified}
                    onChange={(e) => handleChange(Constant.BANK_SWIFT_CODE, e)}
                    className="input"
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid sx={{ marginTop: '20px' }} item md={6} xs={12}>
                  <input
                    className="input"
                    type="input"
                    name="account"
                    value={bankName}
                    disabled={authUser?.userVerified}
                    onChange={(e) => handleChange(Constant.BANK_NAME, e)}
                    style={{ width: '100%' }}
                    placeholder="Bank Name"
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <button
              className="submitButton"
              type="submit"
              onClick={handleSubmit}
              disabled={checkButtonToDisable()}
            >
              Proceed
              <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '5px' }} />
            </button>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '30px' }} className="pBottom">
            <p>
              We will automatically generate a withdrawal request at the end of
              every month depending on your selected preference
            </p>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export default WithdrawlOption
