import React, { useState, useContext, useEffect } from 'react'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import './signIn.scss'
import { Link, useNavigate } from 'react-router-dom'
import { signInAPI } from '../../service/api.js'
import AuthUserContext from '../../authentication/AuthUserContext'
import { snackbarNotification } from '../../redux/snackbar_actions.js'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [showPassword, setShowPassword] = React.useState(false)
  const [isLoadings, setIsLoadings] = useState(true)
  const dispatch = useDispatch()

  const authuser = useContext(AuthUserContext)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (authuser != null) {
      let { from } = location.state || { from: { pathname: '/' } }
      navigate(from)
    }
  }, [authuser])

  const validateInput = () => {
    let error = {}
    let formIsValid = true

    if (email.length === 0) {
      formIsValid = false
      error.email = 'Email cannot be empty'
    } else {
      let lastAtPos = email.lastIndexOf('@')
      let lastDotPos = email.lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        error.email = 'Email is not Valid'
      }
    }
    setErrors(error)
    return formIsValid
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    if (!validateInput()) {
      return
    }
    const data = {
      email: email,
      password: password,
    }

    try {
      signInAPI(data).then((response) => {
        // console.log(response)
        dispatch(snackbarNotification(response))
        if (response.notificationType == 'success') {
          window.location.reload()
        }
      })
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <div className="signin">
      <div className="content">
        <form onSubmit={submitHandler}>
          <p className="chead">Sign In</p>
          <p className="signUp">
            Don't have an account?{' '}
            <Link to="/sign-up" sx={{ marginLeft: '5px' }}>
              Sign up
              <ArrowForwardIcon sx={{ fontSize: '17px', marginLeft: '3px' }} />
            </Link>
          </p>
          <input
            style={{
              border: '1px solid black',
              borderRadius: '3px',
              fontWeight: 500,
            }}
            type="email"
            placeholder="Email ID"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`email ${errors.email && 'err'}`}
          />
          <div></div>
          <span className="error">{errors.email}</span>
          <div>
            <input
              type={showPassword ? 'text' : 'password'}
              style={{
                border: '1px solid black',
                borderRadius: '3px',
                fontWeight: 500,
              }}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="pass"
            />
            <span
              style={{ marginTop: '27px', marginLeft: '-30px' }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </span>
          </div>
          <button className="submitButton" type="submit">
            Sign In
            <ArrowForwardIcon sx={{ fontSize: '18px', marginLeft: '5px' }} />
          </button>
        </form>

        <Link to="/reset-password" className="forgot">
          Forgot Password?
        </Link>
      </div>
      <br />

      <div className="allRight">
        All rights reserved by Koinpr Marketing Private Limited
      </div>
    </div>
  )
}

export default SignIn
