import React, { useEffect, useState } from 'react'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import './setPassword.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { verifyUrlAPI, setPasswordAPI } from '../../service/api.js'
import { snackbarNotification } from '../../redux/snackbar_actions.js'
import { useDispatch } from 'react-redux'
import NotFound from '../NotFound/notFound'

const SetPassword = () => {
  const [validUrl, setValidUrl] = useState(false)
  const [cpassword, setCpassword] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [showPassword, setShowPassword] = React.useState(false)
  const [showCpassword, setShowCpassword] = React.useState(false)
  const dispatch = useDispatch()

  const param = useParams()
  const navigate = useNavigate()

  const [isLoadings, setIsLoadings] = useState(true)

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        const data = verifyUrlAPI(param).then((data) => {
          // console.log(data)
          if (data.success) {
            setValidUrl(true)
          } else {
            setValidUrl(false)
          }
        })
      } catch (error) {
        // console.log(error)
        setValidUrl(false)
      }
    }
    verifyUrl()
  }, [param])

  const validateInput = () => {
    let error = {}
    let formIsValid = true
    // Password
    if (password.length <= 4) {
      formIsValid = false
      error.password = 'Password must have atleast 5 characters'
    } else if (
      !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{4,}$/)
    ) {
      formIsValid = false
      error.password =
        'Password must have at least one uppercase letter, one lowercase letter, one number and one special character'
    }

    // Confirm Password
    if (cpassword.length === 0) {
      formIsValid = false
      error.cpassword = 'Confirm Password cannot be empty'
    } else if (cpassword !== password) {
      formIsValid = false
      error.cpassword = 'Password and Confirm Password do not match'
    }

    setErrors(error)
    return formIsValid
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    if (!validateInput()) {
      // console.log('Failed')
      return
    }
    try {
      setPasswordAPI(param, password).then((response) => {
        // console.log(response)
        dispatch(snackbarNotification(response))
        if (response.notificationType == 'success') {
          navigate('/sign-in')
        }
      })
    } catch (error) {
      // console.log(error)
    }
  }

  return !validUrl ? (
    <NotFound />
  ) : (
    <div className="setpass">
      <div className="content">
        <form onSubmit={submitHandler}>
          <p className="chead">Set Password</p>

          <div>
            <input
              // type="password"
              type={showPassword ? 'text' : 'password'}
              style={{ border: '1px solid black', borderRadius: '4px' }}
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="password"
            />
            <span
              style={{ marginTop: '27px', marginLeft: '-30px' }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </span>
          </div>
          <span className="error">{errors.password}</span>
          <div>
            <input
              // type="password"
              type={showCpassword ? 'text' : 'password'}
              style={{ border: '1px solid black', borderRadius: '4px' }}
              placeholder="Confirm Password"
              value={cpassword}
              onChange={(e) => setCpassword(e.target.value)}
              // className="password"
              className="password"
            />
            <span
              style={{ marginTop: '27px', marginLeft: '-30px' }}
              onClick={() => setShowCpassword(!showCpassword)}
            >
              {showCpassword ? <VisibilityOff /> : <Visibility />}
            </span>
          </div>
          <span className="error">{errors.cpassword}</span>
          <div></div>
          <button className="submitButton" type="submit">
            Submit
            <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '5px' }} />
          </button>
        </form>
      </div>
      {/* <div className="allRight">
        All rights reserved by Koinpr Marketing Private Limited
      </div> */}
    </div>
  )
}

export default SetPassword
