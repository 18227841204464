import { actionType } from "./type";

export const showIndividualMarketplaceData = (payload) => {
  return {
    type: actionType.SHOW_DETAILS_OF_INDIVIDUAL_MARKETPLACE,
    payload: payload,
  };
};

export const addToCart = (payload) => {
  return {
    type: actionType.ADD_TO_CART,
    payload,
  };
};

export const removeFromCart = (id) => {
  return {
    type: actionType.REMOVE_FROM_CART,
    id,
  };
};

export const subtractQuantity = (payload) => {
  return {
    type: actionType.SUB_QUANTITY,
    payload,
  };
};
export const addQuantity = (payload) => {
  return {
    type: actionType.ADD_QUANTITY,
    payload,
  };
};
export const emptyCart = () => {
  return {
    type: actionType.EMPTY_CART,
  };
};

export const activateCart = () => {
  return {
    type: actionType.ACTIVATE_CART,
  };
};

export const orderDetails = (payload) => {
  return {
    type: actionType.ORDER_DETAILS,
    payload,
  };
};

