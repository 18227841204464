import React from 'react'
import '../marketCall/marketCall.scss'

function marketCall() {
  return (
    <div className="ScheduleCallContainer">
      <h1>
        Marketing Roadmap?
        <br /> It’s on us.
      </h1>
      <a
        className="sac"
        href="https://koinpr.com/call"
        target="_blank"
        rel="noopener noreferrer"
      >
        Book a call
      </a>
      <a
        href="https://koinpr.com/telegram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Drop a text on telegram</span>
      </a>
    </div>
  )
}

export default marketCall
