import { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './signUp.scss'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { signUpAPI } from '../../service/api.js'
import AuthUserContext from '../../authentication/AuthUserContext'
import { snackbarNotification } from '../../redux/snackbar_actions.js'
import { useDispatch } from 'react-redux'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const SignUp = () => {
  const navigate = useNavigate()
  const authuser = useContext(AuthUserContext)
  const dispatch = useDispatch()

  useEffect(() => {
    if (authuser != null) {
      navigate('/')
    }
  }, [authuser])

  const [type, setType] = useState('ADVERTISER')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [cpassword, setCpassword] = useState('')
  const [errors, setErrors] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)

  const validateInput = () => {
    let error = {}
    let formIsValid = true

    // Name
    if (fullName.match(/[!@#$%^&*(),.?":{}|<>]/) != null || fullName == '') {
      formIsValid = false
      error.fullName = 'Name must have letters only'
    }

    //Email
    if (email.length === 0) {
      formIsValid = false
      error.email = 'Email cannot be empty'
    } else {
      let lastAtPos = email.lastIndexOf('@')
      let lastDotPos = email.lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        error.email = 'Email is not Valid'
      }
    }

    // Password
    if (password.length <= 8) {
      formIsValid = false
      error.password = 'Password must have atleast 8 characters'
    } else if (
      !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;'"\\|,.<>?`~\-=/]).{8,}$/)
    ) {
      formIsValid = false
      error.password =
        'Password must have at least one uppercase letter, one lowercase letter, one number and one special character'
    }

    // Confirm Password
    if (cpassword.length === 0) {
      formIsValid = false
      error.cpassword = 'Confirm Password cannot be empty'
    } else if (cpassword !== password) {
      error.cpassword = 'Password and Confirm Password do not match'
      formIsValid = false
    }

    setErrors(error)
    return formIsValid
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    const isValid = validateInput()
    if (!isValid) {
      return
    }

    const data = {
      fullName: fullName,
      email: email,
      password: password,
      confirmPassword: cpassword,
      userType: type,
    }

    try {
      signUpAPI(data).then((response) => {
        // console.log(response)
        dispatch(snackbarNotification(response))
        if (response.notificationType == 'success') {
          window.location.reload()
        } else {
          setPassword('')
          setCpassword('')
          setEmail('')
        }
      })
    } catch (error) {
      // console.log(error)
      setPassword('')
      setCpassword('')
      setEmail('')
    }
  }

  return (
    <>
      <div className="signUpHeader">
        <div className="signup">
          <div className="content">
            <div className="head">
              <span className="heading">Let's Get Started</span>
              <span className="subHeading">
                Already have an account?{' '}
                <Link to="/sign-in">
                  Sign In <ArrowForwardIcon sx={{ fontSize: '12px' }} />
                </Link>
              </span>
            </div>
            <form onSubmit={submitHandler}>
              <div className="caard">
                <p>Choose Your Account Type</p>
                <div className="inputs">
                  <div onClick={() => setType('ADVERTISER')} className="input">
                    <label
                      style={{
                        color: '#3D3C3C',
                        fontWeight: '500',
                        fontSize: '15px',
                      }}
                      htmlFor="adv"
                    >
                      I'm an advertiser
                    </label>
                    {type === 'ADVERTISER'?<CheckCircleIcon />:<CheckCircleOutlineIcon/>}
                  </div>
                  <div onClick={() => setType('PUBLISHER')} className="input">
                    <label
                      style={{
                        color: '#3D3C3C',
                        fontWeight: '500',
                        fontSize: '15px',
                      }}
                      htmlFor="pub"
                    >
                      I'm a publisher
                    </label>
                    {type === 'PUBLISHER'?<CheckCircleIcon />:<CheckCircleOutlineIcon/>}
                    
                  </div>
                </div>
              </div>
              <div className="caard textIp ">
                <span>
                  <p>Account Details</p>
                </span>
                <div className="inputs">
                  <div className="col">
                    <input
                      type="text"
                      className={
                        errors.fullName ? 'input-text err' : 'input-text'
                      }
                      placeholder="Full Name"
                      value={fullName}
                      style={{
                        border: '1px solid black',
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#3D3C3C',
                      }}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                    />
                    <span className="error">{errors.fullName}</span>
                  </div>
                  <div className="col">
                    <input
                      type="email"
                      className={errors.email ? 'input-text err' : 'input-text'}
                      placeholder="Email"
                      value={email}
                      style={{
                        border: '1px solid black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <span className="error">{errors.email}</span>
                  </div>
                </div>
                <div className="inputs m-t-23">
                  <div className="col">
                    <span style={{ display: 'flex' }}>
                      <input
                        // type="password"
                        type={showPassword ? 'text' : 'password'}
                        className={
                          errors.password ? 'input-text err' : 'input-text'
                        }
                        placeholder="Password"
                        value={password}
                        style={{
                          border: '1px solid black',
                          fontSize: '15px',
                          fontWeight: '500',
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span
                        style={{ marginTop: '10px', marginLeft: '-35px' }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </span>
                    </span>
                    <span className="error">{errors.password}</span>
                  </div>
                  <div className="col">
                    <span>
                      <input
                        // type="password"
                        type={showConfPassword ? 'text' : 'password'}
                        className={
                          errors.cpassword ? 'input-text err' : 'input-text'
                        }
                        placeholder="Confirm Password"
                        value={cpassword}
                        style={{
                          border: '1px solid black',
                          fontSize: '15px',
                          fontWeight: '500',
                        }}
                        onChange={(e) => setCpassword(e.target.value)}
                        required
                      />
                      <span
                        style={{ marginTop: '10px', marginLeft: '-35px' }}
                        onClick={() => setShowConfPassword(!showConfPassword)}
                      >
                        {showConfPassword ? <VisibilityOff /> : <Visibility />}
                      </span>
                    </span>
                    <span className="error">{errors.cpassword}</span>
                  </div>
                </div>
              </div>
              <div style={{fontSize: '12px'}}>
                    Note: Your password must contain 8 characters including uppercase A-Z, numbers 0-9 and special character.
                </div>
              <div className="proceed">
                <div className="tNc">
                  <span>
                    By proceeding you agree to Koinpr <a href="https://www.koinpr.com/terms-and-conditions" target="_blank">terms and conditions</a>.
                  </span>
                </div>
                <button type="submit" className="submit">
                  Proceed
                  <ArrowForwardIcon
                    sx={{
                      marginLeft: '5px',
                      fontSize: '20px',
                      marginBottom: '2px',
                    }}
                  />
                </button>
              </div>
            </form>
          </div>
          <div className="allRight">
            All rights reserved by Koinpr Marketing Private Limited
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp
